export interface IOneHubClientTrackingModel {
    registrationId: number;
    eventId: ClientHubEvents;
    actedOn: Date;
    actedBy: string;
    eventData: IEventDetails;
}

export enum ClientHubEvents {
    None = 0,
    PINValidationSuccess = 1,
    OTPMailDelivered = 2,
    OTPAuthenticationFailed = 3,
    OTPAccessLocked = 4,
    PINAuthenticationFailed = 5,
    PINAccessLocked = 6,
    ResetPINRequested = 7,
    NewPINGenerated = 8,
    LoginSuccess = 9,
    MergedEmailId = 10,
    UnMergedEmailId = 11,
    PINUpdated = 12,
    EmailIDUpdated = 13,
    DocumentDownloadedFromView = 14
}

export interface IEventDetails {
    fileName: string;
    emailId: string;
    oldEmailId: string;
    newEmailId: string;
}

export enum EventType {
    none = 0,
    organizers = 1,
    clientHub = 2,
}
