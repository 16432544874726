import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { Alert, Modal } from 'react-bootstrap';
import { CheckBoxComponent, CheckBoxSize } from '../../../../components/common/CheckBoxComponent';
import { Loader } from 'react-overlay-loader';
import { EngagementLetterStatus, OrganizerClient, OrganizerStatus, SignatureStatus } from '../../../models/OrganizerComponentModels';
import { UserManagementConstants } from '../../../../components/helper/Constants';

interface ChangeStatusModalProps {
    show: boolean,
    onCancel: () => void;
    selectedDocuments: any[];
    updateEngagementLetter: boolean,
    updateOrganizer: boolean,
    loading: boolean,
    onSelectEngagementLetterCheckBox(checked: boolean): void;
    onSelectOrganizerCheckBox(checked: boolean): void;
    onChangeStatusToManual(): void;
    requstClientInfo: OrganizerClient[];
}

export default class ChangeStatusModal extends React.Component<ChangeStatusModalProps, {}>
{
    public render() {
        const { selectedDocuments } = this.props;
        const client = this.props.requstClientInfo?.filter(doc => doc.signingOrder = 2);
        const isInvalidEngagementLetter = selectedDocuments && selectedDocuments[0] != undefined && selectedDocuments.length === 1 &&
            (selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.NA
            || ((selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.Reviewed && selectedDocuments[0].TPSignerStatus == SignatureStatus.Reviewed && selectedDocuments[0].SpouseSignerStatus != SignatureStatus.None)
            || (selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.Reviewed && selectedDocuments[0].SpouseSignerStatus == SignatureStatus.Reviewed && selectedDocuments[0].TPSignerStatus != SignatureStatus.None))
            || selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.ESigned
            || selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.EngagementLetterDownloaded
            || (selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.Reviewed && selectedDocuments[0].signerCount == 1)
            || selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.ManuallySigned
            || (selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.Reviewed && client && client[0] && client[0].signatureStatus == SignatureStatus.NA)
            || (selectedDocuments[0].engagementLetterStatus == EngagementLetterStatus.Reviewed && client && client[1] && client[1].signatureStatus == SignatureStatus.NA))

        const isInvalidOrganizer = selectedDocuments && selectedDocuments[0] != undefined && selectedDocuments.length === 1 &&
            (selectedDocuments[0].organizerStatus == OrganizerStatus.Completed
                || selectedDocuments[0].organizerStatus == OrganizerStatus.OrganizerDownloaded
                || selectedDocuments[0].organizerStatus == OrganizerStatus.Dropped
                || selectedDocuments[0].organizerStatus == OrganizerStatus.ManuallyCompleted)

        const isSaveDisabled = !this.props.updateEngagementLetter && !this.props.updateOrganizer;

        const isElCheckBoxWarningEnabled = (selectedDocuments &&
            selectedDocuments.length == 1 &&
            selectedDocuments[0] != undefined) &&
            (selectedDocuments[0].engagementLetterStatus !== EngagementLetterStatus.NA && selectedDocuments[0].engagementLetterStatus !== EngagementLetterStatus.ManuallySigned) && isInvalidEngagementLetter;

        const isOrgCheckBoxWarningEnabled = (selectedDocuments &&
            selectedDocuments.length == 1 &&
            selectedDocuments[0] != undefined) &&
            (selectedDocuments[0].organizerStatus !== OrganizerStatus.Dropped && selectedDocuments[0].organizerStatus !== OrganizerStatus.ManuallyCompleted)
            && isInvalidOrganizer;

        let modalBody =
            <div>
                <Alert variant="info" style={{ margin: '1px' }}><i className='fas fa-info-circle' style={{ marginRight: '5px' }}>
                </i>{(this.props.selectedDocuments ? this.props.selectedDocuments.length : 0) + " Organizers selected."}</Alert>
                <div style={{ position: 'relative', padding: '15px' }}>
                    <div style={{ marginLeft: '15px' }}>
                        <CheckBoxComponent
                            size={CheckBoxSize.lg}
                            id={"Engagement-letter-status"}
                            checked={this.props.updateEngagementLetter}
                            onChange={(e: any) => this.props.onSelectEngagementLetterCheckBox(e.target.checked)}
                            text={"Signature document status"}
                            wrapperStyle={{ display: 'inline' }}
                            disabled={isInvalidEngagementLetter}
                        />
                        {
                            isElCheckBoxWarningEnabled &&
                            <span className=" text-danger change-status-error">
                                    <i className="fa fa-exclamation-triangle"></i>  Signature  statuses : Reviewed, E-signed, Downloaded cannot be changed
                            </span>
                        }
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                        <CheckBoxComponent
                            size={CheckBoxSize.lg}
                            id={"Organizer-status"}
                            checked={this.props.updateOrganizer}
                            onChange={(e: any) => this.props.onSelectOrganizerCheckBox(e.target.checked)}
                            text={"Organizer status"}
                            wrapperStyle={{ display: 'inline' }}
                            disabled={isInvalidOrganizer}
                        />
                        {
                            isOrgCheckBoxWarningEnabled &&
                            <span className=" text-danger change-status-error">
                                <i className="fa fa-exclamation-triangle"></i>  Organizer statuses : Completed, Downloaded cannot be changed
                            </span>
                        }
                    </div>
                </div >
            </div>

        const modalAlert = <div style={{ fontSize: '12px', marginLeft: '15px' }}>
            <div style={{ color: 'red', fontWeight: 'bold' }} >{UserManagementConstants.PleaseNoteText} <br /></div>
            Change status will apply only following statuses : <br />
            <b>Signature document</b>: {UserManagementConstants.SigningRelatedStatusNames}<br />
            <b>Organizer</b>: {UserManagementConstants.OrganizerRelatedStatusNames}
        </div>

        return (
            <Modal className="download-options-modal" show={this.props.show} onHide={this.props.onCancel}
                style={{ position: "absolute", top: "0%", left: "25%", right: "0%", width: "700px" }}
            >
                <Modal.Header closeButton data-test-auto="E9FF957A-1DE3-4FBA-8DD9-987ADBB404BA">
                    <Modal.Title>
                        <>
                            <i className="fa fa-random" style={{ color: '#454545', paddingRight: '6px' }}></i>
                            Change Status to Manual process
                        </>
                    </Modal.Title>
                </Modal.Header>
                {this.props.loading &&
                    <div style={{ height: '200px' }}>
                        <Loader loading={this.props.loading} text={"Loading..."} />
                    </div>}
                {!this.props.loading &&
                    <Modal.Body>
                        {modalBody}
                        {
                            selectedDocuments && selectedDocuments.length > 1 && modalAlert
                        }
                    </Modal.Body>

                }
                <Modal.Footer>
                    <Bootstrap.Button
                        className="btn-white"
                        variant="default"
                        data-test-auto="28A5846C-E7CE-420B-9E9F-D1BE18DE1C90"
                        onClick={this.props.onCancel}
                    ><i className="fas fa-times"></i>
                        Cancel
                    </Bootstrap.Button>

                    <Bootstrap.Button
                        variant='info'
                        data-test-auto="056DD1B3-F56D-439C-B5E0-8998A4BDDAC7"
                        disabled={isSaveDisabled}
                        onClick={this.props.onChangeStatusToManual}
                    > <i className='fas fa-save'></i>Save

                    </Bootstrap.Button>

                </Modal.Footer>
            </Modal>
        )
    }
}
