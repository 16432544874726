import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime, insertWhiteSpace, capitaliseString, handleEmailEventCase, userName } from '../../../../components/helper/HelperFunctions';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { OverlayLoader } from '../../../../components/helper/OverlayLoader';
import { MailEvent } from '../../../../components/common/TaxReturn';
import { IOneHubClientTrackingModel, ClientHubEvents, EventType } from 'src/components/common/OneHubClientTracking';
import {
    OrganizerTransaction, OrganizerEvent,
    OrganizerEventList
} from './../../../models/OrganizerComponentModels';
import { ClientTrackingEvents, ClientHubEventValue } from './../../Helper/OrganizerConstants'

interface OrganizerClientTrackingProps {
    show: boolean;
    onClose: () => void;
    clientTracking: OrganizerTransaction[];
    loading: boolean;
    clientHubTracking?: IOneHubClientTrackingModel[];
}

const OrganizerClientTrackingModal: React.StatelessComponent<OrganizerClientTrackingProps> = ({ clientTracking, show, onClose, loading, clientHubTracking }) => {

    const nonRequiredEmailEvents = [MailEvent.Processed, MailEvent.Click, MailEvent.SpamReport, MailEvent.ScheduleFailed, MailEvent.Deferred]
    const filteredData = clientTracking?.filter((item) =>
        !nonRequiredEmailEvents.includes(item?.eventData?.mailEventId) &&
        item?.eventId !== OrganizerEvent.OrganizerReviewIgnored
    );

    const defaultFormatter = (cell: any, row: any) => {
        return cell;
    }

    const actedOnFormatter = (cell: any, row: any) => {
        return GetFormatedDateTime(cell);
    }

    const createTotalPages = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    const getRecipientName = (recipient) => {
        return `${recipient?.firstName || ''}${recipient?.lastName ? ' ' + recipient.lastName : ''}`.trim();
    };

    const getActedBy = (actedBy) => {
        return `${actedBy?.firstName || ''} ${actedBy?.lastName ? ' ' + actedBy?.lastName : ''}`.trim();
    };

    const getBaseEvent = (cell) => {
        return `${OrganizerEventList[OrganizerEvent[cell]]}`;
    };

    const getEventName = (mailEventId, recipientName, baseEvent, isResentAccessLinkSentEvent) => {
        const baseEventName = `${baseEvent} - ${handleEmailEventCase(MailEvent[mailEventId])}`;

        if (mailEventId === MailEvent.None) {
            return isResentAccessLinkSentEvent
                ? `${baseEvent} (${recipientName})`
                : `${baseEvent} - sent (${recipientName})`;
        } else if (mailEventId === MailEvent.Opened) {
            return baseEventName;
        } else {
            return `${baseEventName} (${recipientName})`;
        }
    };

    const getAccessCodeEventName = (mailEventId, recipientName, baseEvent) => {
        const baseEventName = `${baseEvent} - ${handleEmailEventCase(MailEvent[mailEventId])}`;

        if (mailEventId === MailEvent.None) {
            return `${baseEvent} - sent`;
        } else if (mailEventId === MailEvent.Delivered) {
            return `${baseEventName} (${recipientName})`;
        } else {
            return baseEventName
        }
    }

    const isBlankActedByForAutomaticReminder = (eventId, actedBy) =>
        [OrganizerEvent.AutomaticOrganizerReminder, OrganizerEvent.AutomaticSigningReminder].includes(eventId) && !actedBy;


    const actedByFormatter = (cell, row) => {
        const system = "System";
        const { recipient } = row?.eventData || {};
        const recipientName = getRecipientName(recipient);
        const systemEvents = [MailEvent.Processed, MailEvent.Delivered, MailEvent.Bounce, MailEvent.Dropped];
        const eventId = row?.eventId;
        const mailEventId = row?.eventData?.mailEventId;
        const actedBy = getActedBy(row.actedBy) || "";

        if (eventId === OrganizerEvent.OTPAccesslocked) {
            return system;
        }

        if (row.type === EventType.clientHub) {
            return cell ? cell : '';
        }

        if (isBlankActedByForAutomaticReminder(eventId, actedBy)) {
            return system;
        }

        if (eventId) {
            if (mailEventId === MailEvent.None) {
                return actedBy;
            } else if (systemEvents.includes(mailEventId)) {
                return system;
            } else {
                return recipientName;
            }
        }
        return actedBy;
    };

    const clientHubEvent = (cell: ClientHubEvents, row: any) => {
        let eventName = "";
        switch (cell) {
            case ClientHubEvents.PINValidationSuccess:
            case ClientHubEvents.OTPMailDelivered:
            case ClientHubEvents.OTPAuthenticationFailed:
            case ClientHubEvents.OTPAccessLocked:
            case ClientHubEvents.PINAuthenticationFailed:
            case ClientHubEvents.PINAccessLocked:
            case ClientHubEvents.ResetPINRequested:
            case ClientHubEvents.NewPINGenerated:
            case ClientHubEvents.LoginSuccess:
            case ClientHubEvents.PINUpdated:
                eventName = ClientHubEventValue[cell];
                break;
            case ClientHubEvents.MergedEmailId:
                eventName = ClientHubEventValue[cell].replace("<EmailID>", row.eventData?.emailId);
                break;
            case ClientHubEvents.UnMergedEmailId:
                eventName = ClientHubEventValue[cell].replace("<EmailID>", row.eventData?.emailId);
                break;
            case ClientHubEvents.EmailIDUpdated:
                eventName = ClientHubEventValue[cell]
                    .replace("<OldEmailID>", row.eventData?.oldEmailId)
                    .replace("<NewEmailID>", row.eventData?.newEmailId);
                break;
            case ClientHubEvents.DocumentDownloadedFromView:
                eventName = ClientHubEventValue[cell].replace("<DocumentName>", row.eventData?.fileName);
                break;
        }
        return eventName;
    };

    const eventFormatter = (cell, row) => {
        const mailEventId = row.eventData.mailEventId;
        const baseEvent = getBaseEvent(cell);
        const actedBy = getActedBy(row.actedBy);
        const recipientName = getRecipientName(row?.eventData?.recipient);
        const onehubEvent = "Client Portal: ";

        if (row.type === EventType.clientHub) {
            return clientHubEvent(cell, row);
        } 
        else
        {
            switch (cell) 
            {
            case OrganizerEvent.UploadedDocument:
            case OrganizerEvent.DeleteSourceDocument:
                return (row.eventData.isFromOneHub ? onehubEvent : '') + `${baseEvent}${row.eventData.fileName ? ' - ' + row.eventData.fileName : ''}`;

            case OrganizerEvent.Emailed:
            case OrganizerEvent.AutomaticSigningReminder:
            case OrganizerEvent.AutomaticOrganizerReminder:
            case OrganizerEvent.ManualOrganizerReminder:
            case OrganizerEvent.SourceDocumentReminder:
            case OrganizerEvent.ManualSigningReminder:
                return getEventName(mailEventId, recipientName, baseEvent, false);

            case OrganizerEvent.ResentAccessLink:
                return getEventName(mailEventId, recipientName, baseEvent, true);

            case OrganizerEvent.AccessCodeEmail:
                return getAccessCodeEventName(mailEventId, recipientName, baseEvent);

            case OrganizerEvent.SourceDocumentUploadMarkedCompleted:
                return (row.eventData.isFromOneHub ? onehubEvent : '') + baseEvent;
            case OrganizerEvent.RestoreRecycledOrganizer:
                return baseEvent;
            case OrganizerEvent.EmailUpdated:
            case OrganizerEvent.SecondSignerEmailUpdated:
                return (row.eventData.isFromOneHub ? onehubEvent : "") + `${recipientName}'s ${baseEvent}`;
            case OrganizerEvent.OTPAccesslocked:
                return `${actedBy} ${baseEvent}`;
            case OrganizerEvent.OTPAccessUnlocked:
                return `${baseEvent}`;
            default:
                return (row.eventData.isFromOneHub ? onehubEvent : '') + baseEvent;
            }
        }
    };

    const columns = [
        {
            header: 'Events',
            key: 'eventId',
            isKey: true,
            dataFormat: eventFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'By',
            key: 'actedBy',
            isKey: false,
            dataFormat: actedByFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'Event Date',
            key: 'actedOn',
            isKey: false,
            dataFormat: actedOnFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'Event Data',
            key: 'eventData',
            isKey: false,
            hidden: true,
            dataFormat: defaultFormatter,
            columnClassName: '',
            dataSort: false,
            toolTip: false
        }
    ];

    const options = {
        sizePerPage: 20,
        paginationShowsTotal: createTotalPages,
    };

    const data = filteredData?.length > 0 ? filteredData?.map((model, index) => {

        return {
            eventId: model.eventId,
            actedBy: model.actedBy,
            actedOn: model.actedOn,
            eventData: model.eventData,
            type: EventType.organizers
        }
    }) : [];

    const clientHubEvents = clientHubTracking
            ? clientHubTracking.map((model) => {
                  return {
                      eventId: model.eventId,
                      actedBy: model.actedBy,
                      actedOn: model.actedOn,
                      eventData: model.eventData,
                      type: EventType.clientHub
                  };
              })
            : [];

        const allEvents: any[] = [];
        allEvents.push(...data, ...clientHubEvents);
        allEvents.sort((a, b) => {
            const dateA = new Date(a.actedOn);
            const dateB = new Date(b.actedOn);
            return dateA.getTime() - dateB.getTime();
        });

    const modalBody = filteredData ?
        <BootstrapTable
            data={allEvents}
            options={options}
            striped pagination={true}>
            {columns.map((value, index) => {
                return <TableHeaderColumn key={index} hidden={value.hidden} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat}
                    columnClassName={value.columnClassName} dataSort={true} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
            })}
        </BootstrapTable> : (<OverlayLoader
            show={show}
            text={"Loading, please wait..."} />);

    return <Modal className="client-tracking-modal" show={show} onHide={() => { onClose() }}>
        <Modal.Header closeButton>
            <Modal.Title>
                <span className='text-secondary fas fa-user-clock'
                    style={{ color: 'grey', marginRight: '5px' }}>
                </span>
                Client Tracking History
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <LoadingOverlay style={{ height: '100%' }}>
                    {modalBody}
                    <Loader loading={loading} text={"Loading..."} />
                </LoadingOverlay>
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}


export { OrganizerClientTrackingModal }

