import * as bootbox from 'bootbox';
import { Guid } from 'guid-typescript';
import * as React from 'react';
import { Button, Card, Col, Overlay, Popover, Row, Tabs } from 'react-bootstrap';
import { RadioButtonComponent } from '../../../../../components/common/RadioButtonComponent';
import { CustomQuestionsTabConstants, EngagementLetterControlDeleteWarning, SectionDeleteWarning, TemplateModalConstants } from '../../../../../components/helper/Constants';
import { phoneNumberDisplay, updateSectionAndQuestionnaireOrder } from '../../../../../components/helper/HelperFunctions';
import { isValidEmailAddress } from '../../../../../components/helper/Validations';
import { IUserProfile } from '../../../../../components/navigation/profile/ProfileObjects';
import * as UserSignatureStore from '../../../../store/UserSignatureStore';
import { VenusNotifier } from './../../../../../components/helper/VenusNotifier';
import { IPdfDocumentFacade } from './../../../../../Core/Utilities/PdfDocumentFacade';
import { IUserModel } from './../../../../../Core/ViewModels/User/UserViewModel';
import { ICompanyData } from './../../../../../store/company/CompanyStore';
import * as UserStore from '../../../../../store/UserManagement/UserStore';
import * as UserSettingStore from '../../../../../store/UserManagement/UserSettingStore';
import {
    AdditionalEsignDocumentControlData,
    AdditionalEsignDocumentControls,
    AdditionalEsignModel,
    AdditionalQuestionsForm,
    AdditionalQuestionsListItem, BookmarkPage, Bookmarks,
    SignatureCheckBoxControl,
    ChoosableControlData,
    Control,
    ControlRole, ControlType,
    EngagementLetterForm, Form, FormGroup,
    FormGroupStatus, FormType, GroupType,
    OrganizerBookmarkPage,
    OrganizerBookmarks, OrganizerClient, OrganizerClientType, OrganizerCompanySettings, OrganizerDocument,
    OrganizerDocumentSettings,
    OrganizerForm, OrganizerTabs,
    PreparerMessage, QuestionControl, QuestionType,
    SignatureRadioButtonControl,
    SectionControl, SignatureControlsDictionary, ProcessStatus
} from './../../../../models/OrganizerComponentModels';
import { closeOrganizerProcessMessage, DeliveryInfo, ClientManagementConstants } from './../../../Helper/OrganizerConstants';
import { validateOrganizerClient, validateOrganizerDocumentSettings, validateLocationData, validateEROData, validateClientName } from './../../../Helper/OrganizerHelperFunctions';
import {
    AdditionalQuestion,
    CustomQuestions, PdfProperties, RadioButtonProperties
} from './../../../Helper/PdfHelper';
import { CustomWindow, CustomWindowBody, CustomWindowFooter } from './CustomWindowComponents/CustomWindow';
import AdditionalEsignTab from './Tabs/AdditionalEsignTab';
import ClientInfoTab from './Tabs/ClientInfoTab';
import { DeliveryOptionsTab } from './Tabs/DeliveryOptionsTab';
import EngagementLetterTab from './Tabs/EngagementLetterTab';
import OrganizerTab from './Tabs/OrganizerTab';
import RemovedTab from './Tabs/RemovedTab';
import { ResourceIdConstants } from '../../../../../components/helper/Rbac/RbacConstants';
import NewClientModal from '../../../../../modules/clientManagement/NewClientModal';
import ExistingClientDataComparisonModal from '../../../../../modules/clientManagement/ExistingClientDataComparisonModal';
import { addNewClientInfo } from '../../../../../modules/clientManagement/NewClientModal/newClientModel.apis'
import { compareClientInfo, getClientInfo } from '../../../../../modules/clientManagement/clientManagement.apis'
import { ClientInfoMismatchType, ClientInfoProperty, IClientInfoComparisonResult, IClientInfoMismatchViewModel, IClientInfoViewModel, initialClientInfoComparisonResult, initialClientInfoViewModel, IPrefilledClientInfoDetails } from '../../../../../modules/clientManagement/clientManagement.types';
import { updateExistingClientInfo } from '../../../../../modules/clientManagement/ExistingClientDataComparisonModal/existingClientDataComparisonModal.apis';
import CustomQuestionsTab from './Tabs/CustomQuestionsTab';
import { HasCustomQuestion, ICustomQuestionTemplate, ICustomQuestionsState, ISelectedTemplate, ITemplate } from 'src/Core/ViewModels/CompanySettings/CustomQuestionModel';
import { createNewClientInfoComparisonResult } from '../../../../../modules/clientManagement/NewClientModal/newClientModal.model';
import { ILocations } from 'src/Core/ViewModels/Company/CompanyViewModel';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { IPartnerModel } from 'src/Core/ViewModels/User/UserViewModel';
import { convertPartnersToDropDownList } from 'src/components/helper/UserHelperFunctions';
import LocationMismatchActionModal from 'src/components/common/LocationMismatchActionModal/LocationMismatchActionModal';

interface ProcessOrganizerModalProps {
    document: OrganizerDocument;
    order: number;
    onClose: (documentId: number) => void;
    onSave?: (model: OrganizerDocument, callback: (id: number) => void) => void;
    onFinish?: (model: OrganizerDocument, callback: (id: number) => void) => void;
    company: ICompanyData;
    users: IUserModel[];
    preparerMessages: PreparerMessage[];
    updateDocument: (document: OrganizerDocument) => void;
    companySettings: OrganizerCompanySettings;
    readOnlyView?: boolean;
    showSignaturePanel?: boolean;
    additionalQuestionsReadonlyMode?: boolean;
    generateTaxpayerView: (document: OrganizerDocument, callback: () => void) => void;
    sourceDocumentEnabled?: boolean;
    getUploadLink: (callback: (data?: any) => void) => void;
    requestDocuments: (documentGuid: string, taxYear: number, callback?: (esignDocs: AdditionalEsignModel[]) => void) => void;
    deleteBlobAdditionalEsign: (fileGuid: string, callback?: () => void) => void;
    deleteEsignDocuments: (ids: number[], callback?: () => void) => void;
    updateAdditionalEsignDocument: (data: AdditionalEsignModel, callback: () => void) => void;
    partners: UserStore.IPartnerData;
    userSettings: UserSettingStore.UserSettings;
    userBasicProfile: IUserProfile;
    userSignatures: UserSignatureStore.IUserSignatures;
    updateEROSignatureStamp?(userId: number): void;
    requestDownloadPath(userId: number): void;
    additionalEsignReadOnly: boolean;
    batchId?: string;
    batchView?: boolean;
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName: string, isBatch: boolean, callback?: (data?: any) => void) => void;
    isBatchOrganizer?: boolean;
    getCustomQuestionTemplates: (callback: (customQuestions: ICustomQuestionsState) => void, failureCallback: () => void) => void;
    getCustomQuestionTemplate: (id: number, callback: (template: ICustomQuestionTemplate) => void, failureCallback: () => void) => void;
    getCustomQuestion: (id: number, callback: (template: ICustomQuestionTemplate) => void, failureCallback: () => void) => void;
    isCustomQuestionEditable: boolean;
    defaultERO: number;
    userLocations: UserStore.ILocationData;
    pageReload: () => void;
}

interface ProcessOrganizerModalState {
    activeKey: OrganizerTabs;
    renderTrigger: string;
    loading: boolean;
    showFinishPopover: boolean;
    questionOrder: AdditionalQuestionsListItem[];
    questionPage: number;
    pdfData: string;
    pdfURL: string;
    sectionControls: SectionControl[];
    questionControls: QuestionControl[];
    deletedEsignDocuments: number[];
    questionnaireModel: any;
    showNewClientModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    actionType: ActionType;
    isMarriedFileJointly: boolean;
    showExistingClientDataComparisonModal: boolean;
    showLocationMismatchActionModel: boolean;
    prefilledClientInfoDetails: IPrefilledClientInfoDetails;
    clientInfoViewModel?: IClientInfoViewModel;
    templates: any,
    selectedTemplate: any,
    locationsState: IDropdown[],
    erosState: IDropdown[],
    isLocationMismatch: boolean
}

const enum ActionType {
    SaveAndClose,
    Finish
}
const viewMax = OrganizerTabs.DeliveryOptions;
const viewMin = OrganizerTabs.ClientInfo;

export default class ProcessOrganizerModal extends React.Component<ProcessOrganizerModalProps, ProcessOrganizerModalState> {

    finishPopoverRef: any;

    constructor(props: ProcessOrganizerModalProps) {

        super(props);

        this.state = {
            activeKey: OrganizerTabs.ClientInfo,
            renderTrigger: '',
            loading: false,
            showFinishPopover: false,
            questionOrder: [],
            questionPage: 0,
            pdfData: '',
            pdfURL: '',
            sectionControls: [],
            questionControls: [],
            deletedEsignDocuments: [],
            showNewClientModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            actionType: ActionType.SaveAndClose,
            isMarriedFileJointly: false,
            showExistingClientDataComparisonModal: false,
            showLocationMismatchActionModel: false,
            prefilledClientInfoDetails: {},
            questionnaireModel: null,
            templates: [],
            selectedTemplate: null,
            locationsState: [],
            erosState: [],
            isLocationMismatch: false
        }

        this.finishPopoverRef = null;
    }
    private editClientInfoResourceId: string = ResourceIdConstants.ProcessOrganizer;

    componentDidMount() {
        this.setState({
            renderTrigger: Guid.create().toString()
        });

        const questionIndex = this.props.document.formGroups.findIndex(group => group.type === GroupType.AdditionalQuestions);
        if (questionIndex !== -1) {
            this.createQuestionOrder(this.props.document.formGroups[questionIndex].forms as AdditionalQuestionsForm[]);
        }

        if (this.props.document) {
            getClientInfo(this.props.document.clientId, this.editClientInfoResourceId, this.preFillClientInfo, this.setDefaultERO);

            if (this.props.getCustomQuestionTemplates) {
                this.props.getCustomQuestionTemplates(this.onGetCustomQuestionsCallback, this.onFailureCallback);
            }

            if (this.props.company && this.props.company.companyProfile &&
                this.props.company.companyProfile && this.props.company.companyProfile.locations) {
                this.getOfficeLocations(this.props.company.companyProfile.locations);
            }

            if (this.props.partners) {
                this.getERO(this.props.partners.partners);
            }

            this.initializePDFDetail();
        }
    }


    componentWillReceiveProps(nextProps: ProcessOrganizerModalProps) {
        if (nextProps.document.document != undefined && nextProps.document.document.parts[0]?.fileName) {
            this.setState({
                pdfURL: nextProps.document.document.parts[0].fileName
            });
            if (this.state.pdfData == '')
                this.getBase64(nextProps.document.document.parts[0].fileName);
        }
        if (nextProps.document.organizerClients) {
            this.setState({
                isMarriedFileJointly: nextProps.document.organizerClients.some(s => s.clientType == OrganizerClientType.Spouse)
            });
        }
    }

    componentWillUnmount() {
        this.setState({ pdfData: '' });
    }

    preFillClientInfo = (clientManagementClientInfo?: IClientInfoViewModel) => {

        let organizerDocument = this.props.document;
        const taxpayer = organizerDocument.organizerClients.find(client => client.clientType === OrganizerClientType.Taxpayer);
        const spouse = organizerDocument.organizerClients.find(client => client.clientType === OrganizerClientType.Spouse);

        if (clientManagementClientInfo) {
            if (clientManagementClientInfo.locationId && organizerDocument.locationId <= 0) {
                organizerDocument.locationId = clientManagementClientInfo.locationId;
                this.setPrefillInfo(ClientInfoProperty.LocationId);
            }

            if (clientManagementClientInfo.locationId && (clientManagementClientInfo.locationId == organizerDocument.locationId)) {
                this.setPrefillInfo(ClientInfoProperty.LocationId);
            }

            let clientManagementERO = this.getEROValue(organizerDocument, clientManagementClientInfo);
            let orgEROValue = organizerDocument.ero;

            if (clientManagementERO == orgEROValue) {
                this.setPrefillInfo(ClientInfoProperty.EROId);
            }

            if (clientManagementERO && (orgEROValue == undefined || orgEROValue <= 0)) {
                organizerDocument.ero = clientManagementERO;
                this.setPrefillInfo(ClientInfoProperty.EROId);
            }
        }

        if (taxpayer) {
            if ((!taxpayer.name || taxpayer.name == '') && clientManagementClientInfo?.name) {
                taxpayer.name = clientManagementClientInfo.name;
                this.setPrefillInfo(ClientInfoProperty.Name);
            }
            if ((!taxpayer.email || taxpayer.email == '') && clientManagementClientInfo?.emailAddress) {
                taxpayer.email = clientManagementClientInfo.emailAddress;
                this.setPrefillInfo(ClientInfoProperty.EmailAddress);
            }
            if ((!taxpayer.mobileNumber || taxpayer.mobileNumber == '') && clientManagementClientInfo?.mobileNumber) {
                taxpayer.mobileNumber = clientManagementClientInfo.mobileNumber;
                this.setPrefillInfo(ClientInfoProperty.MobileNumber);
            }
            if ((!taxpayer.countryCode || taxpayer.countryCode == '') && clientManagementClientInfo?.countryCode) {
                taxpayer.countryCode = clientManagementClientInfo.countryCode;
                this.setPrefillInfo(ClientInfoProperty.CountryCode);
            }
        }
        if (spouse) {
            if ((!spouse.name || spouse.name == '') && clientManagementClientInfo?.spouseName) {
                spouse.name = clientManagementClientInfo.spouseName;
                this.setPrefillInfo(ClientInfoProperty.SpouseName);
            }
            if ((!spouse.email || spouse.email == '') && clientManagementClientInfo?.spouseEmailAddress) {
                spouse.email = clientManagementClientInfo.spouseEmailAddress;
                this.setPrefillInfo(ClientInfoProperty.SpouseEmailAddress);
            }
            if ((!spouse.mobileNumber || spouse.mobileNumber == '') && clientManagementClientInfo?.spouseMobileNumber) {
                spouse.mobileNumber = clientManagementClientInfo.spouseMobileNumber;
                this.setPrefillInfo(ClientInfoProperty.SpouseMobileNumber);
            }
            if ((!spouse.countryCode || spouse.countryCode == '') && clientManagementClientInfo?.spouseCountryCode) {
                spouse.countryCode = clientManagementClientInfo.spouseCountryCode;
                this.setPrefillInfo(ClientInfoProperty.SpouseCountryCode);
            }
        }
        this.props.document.organizerClients.map(client => {
            if (client.clientType === OrganizerClientType.Taxpayer) {
                return taxpayer;
            }
            else if (client.clientType === OrganizerClientType.Spouse) {
                return spouse;
            }
            return client;
        });
        this.props.updateDocument(this.props.document);
        this.setState({ clientInfoViewModel: clientManagementClientInfo });
    }

    setDefaultERO = () => {
        if (!this.state.clientInfoViewModel) {
            let defaultERO = this.props.defaultERO;
            let orgEROValue = this.props.document.ero;

            if (defaultERO > 0 && (orgEROValue == undefined || orgEROValue <= 0)) {
                let organizerDocument = this.props.document;
                organizerDocument.ero = defaultERO;
            }
        }
    }

    getEROValue = (organizerDocument: OrganizerDocument, clientManagementClientInfo: IClientInfoViewModel) => {
        let clientManagementEROVal = 0;
        let clientManagementERO = clientManagementClientInfo.ero;
        let defaultERO = this.props.defaultERO;
        let orgEROValue = organizerDocument.ero;

        if (clientManagementERO) {
            clientManagementEROVal = parseInt(clientManagementERO);
        }

        if (clientManagementEROVal <= 0) {
            if (defaultERO > 0 && (orgEROValue == undefined || orgEROValue <= 0)) {
                organizerDocument.ero = defaultERO;
            }
        }

        return clientManagementEROVal;
    }

    setPrefillInfo = (propertyName: ClientInfoProperty) => {
        this.setState(state => ({ prefilledClientInfoDetails: { ...state.prefilledClientInfoDetails, [propertyName]: true } }));
    }

    resetPrefillInfoDetails = (item: ClientInfoProperty) => {
        this.setState(state => ({ prefilledClientInfoDetails: { ...state.prefilledClientInfoDetails, [item]: false } }));
    }

    getBase64 = (url: string) => {
        const _self = this;
        fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                let base64Data: any;
                reader.onloadend = function () {
                    base64Data = _self.extractBase64(reader.result);
                    _self.setState(
                        {
                            pdfData: base64Data
                        });
                }

            })
    }

    extractBase64 = (dataUrl: any) => {
        var index = dataUrl.indexOf('base64,');
        if (index == -1) {
            return null;
        }
        return dataUrl.substring(index + 7);
    }

    handleTemplateSelection = (template) => {
        this.setState({ selectedTemplate: template, questionnaireModel: template });
    }

    handleTemplateUpdate = (template) => {
        this.setState({ questionnaireModel: template });
    }

    onGetCustomQuestionsCallback = (customQuestions) => {
        this.setState({ loading: false })
        let defaultTemplate;
        const templates: ISelectedTemplate[] = [
            {
                formId: 0,
                template: {
                    templateId: -1,
                    name: CustomQuestionsTabConstants.NoTemplate,
                    description: ""
                },
                label: CustomQuestionsTabConstants.NoTemplate,
                value: -1,
                title: CustomQuestionsTabConstants.NoTemplate,
                description: "",
                isDefault: false,
                isDeleted: false,
                sections: []
            }];

        customQuestions?.forEach((customQuestion) => {
            const template: ISelectedTemplate = {
                label: customQuestion.name,
                title: customQuestion.name,
                value: customQuestion.id,
                template: customQuestion,
                isDefault: customQuestion.isDefault,
                description: customQuestion.description,
                sections: []
            }
            if (template.isDefault) {
                defaultTemplate = template;
            }
            templates.push(template);
        })
        this.setState({ templates: templates })
        if (this.props.document?.customQuestionTemplateId > 0) {
            this.props.getCustomQuestionTemplate(this.props.document.id, this.onGetSelectedCustomQuestionCallback, this.onFailureCallback);
        }
        else if (defaultTemplate && this.props.document.hasCustomQuestion === HasCustomQuestion[0]) {
            this.props.getCustomQuestion(defaultTemplate.value, this.onGetSelectedCustomQuestionCallback, this.onFailureCallback);
        }
        else if (this.props.document.hasCustomQuestion === HasCustomQuestion[1]) {
            this.handleTemplateSelection(this.state.templates[0]);
        }

    }

    getOfficeLocations = (locations: ILocations[]) => {

        let locationList: IDropdown[] = [];

        locations?.forEach((location: ILocations) => {
            const locationVal: IDropdown = {
                label: location.name,
                value: location.locationId
            }

            locationList.push(locationVal);
        })

        this.setState({ locationsState: locationList })
    }

    getERO = (eros: IPartnerModel[]) => {
        let partnerList = convertPartnersToDropDownList(eros);
        this.setState({ erosState: partnerList })
    }

    onGetSelectedCustomQuestionCallback = (template: ICustomQuestionTemplate) => {
        let label = TemplateModalConstants.CustomTemplate;
        this.state.templates.forEach((item) => {
            if (item.value === template.template.templateId) {
                label = template.template?.name;
            }
        })
        let selectedTemplate = { ...template, label: label, value: template.template?.templateId };
        this.setState({ loading: false, selectedTemplate: selectedTemplate })
        this.handleTemplateSelection(selectedTemplate);
    }

    onFailureCallback = () => {
        this.setState({ loading: false });
    }

    createQuestionOrder = (forms: AdditionalQuestionsForm[]) => {
        const questionControls: QuestionControl[] = [];
        let index = 1;
        const sectionControls: SectionControl[] = [];
        forms.map((form) => {
            form.formData.controlList?.map((control: Control) => {
                switch (control.controlType) {
                    case ControlType.Section:
                        let _secControl = control as SectionControl;
                        sectionControls.push(_secControl);
                        index++;
                        break;
                    case ControlType.QuestionControl:
                        let _quesControl = control as QuestionControl;
                        questionControls.push(_quesControl);
                        break;
                }
            });
        })

        this.setState({
            questionControls: questionControls,
            sectionControls: sectionControls
        }, () => {
            this.updateQuestionOrder()
        });

    }

    handleDeleteLastSectionQuestion = (questionId: string, page: number, questionControls: QuestionControl[], sectionId: string, confirmDelete: boolean) => {
        const _questions = questionControls.filter(x => x.sectionId == sectionId && x.parentQuestionId == Guid.EMPTY);
        if (_questions && _questions.length == 1) {
            if (!confirmDelete) {
                const _self = this;
                bootbox.dialog({
                    title: SectionDeleteWarning.QuestionDelete.Title,
                    message: SectionDeleteWarning.QuestionDelete.Message,
                    buttons: {
                        cancel: {
                            label: '<i class="glyphicon glyphicon-remove"></i>' + SectionDeleteWarning.QuestionDelete.Cancel,
                            className: 'btn-white',
                            callback: function () { return }
                        },
                        confirm: {
                            label: '<i class="glyphicon glyphicon-ok"></i>' + SectionDeleteWarning.QuestionDelete.Confirm,
                            className: 'btn-info',
                            callback: function () { _self.handleDeleteQuestion(questionId, page, true); }
                        }
                    }
                });
            }
            else {
                this.handleDeleteSection(sectionId);
            }
            return true;
        }
        return false;
    }

    handleDeleteQuestion = (questionId: string, page: number, confirmDelete: boolean = false) => {
        let _questionControls = [...this.state.questionControls];
        let index = _questionControls.findIndex(a => a.id == questionId);
        if (index != -1) {
            let control = _questionControls.filter(x => x.id === questionId)[0];
            if (control.sectionId != Guid.EMPTY) {
                if (this.handleDeleteLastSectionQuestion(questionId, page, _questionControls, control.sectionId, confirmDelete)) {
                    return;
                }
            }
            if (control && control.subQuestions && control.subQuestions?.length > 0) {
                control.subQuestions.forEach((subQMap) => {
                    subQMap.subQuestionIds.forEach((subQ) => {
                        let subQIndex = _questionControls.findIndex(a => a.id == subQ);
                        const subQuestion = _questionControls.filter(a => a.id == subQ)[0];
                        if (subQIndex != -1) {
                            _questionControls.splice(subQIndex, 1);
                            this.onQuestionControlRemove(subQuestion, page);
                        }
                    })

                });
            }
            _questionControls.splice(index, 1);
            this.onQuestionControlRemove(control, page);
        }
        this.setState({
            questionControls: _questionControls
        }, () => this.updateQuestionOrder());

    }

    handleDeleteSection = (id: string) => {
        let { sectionControls } = this.state;
        let _sectionCtrlIndex = sectionControls.findIndex(a => a.id == id);
        const formGroups = this.props.document.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0].forms as AdditionalQuestionsForm[];
        if (_sectionCtrlIndex != -1) {
            const secControl = sectionControls.filter(x => x.id == id)[0];
            const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == id)[0]);
            sectionControls.splice(_sectionCtrlIndex, 1);
            this.onSectionControlRemove(secControl, pageNo + 1);
        }
        let _questionControls = [...this.state.questionControls];
        let _questionsSection = _questionControls.filter(a => a.sectionId == id);
        if (_questionsSection?.length > 0) {
            _questionsSection.forEach((_ques) => {
                let index = _questionControls.findIndex(a => a.id == _ques.id);
                const quesControl = _questionControls.filter(x => x.id == _ques.id)[0];
                //const formGroups = this.props.document.formGroups[4].forms as AdditionalQuestionsForm[];
                const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == _ques.id)[0]);
                if (index != -1) {
                    let control = _questionControls.filter(x => x.id === _ques.id)[0];
                    if (control && control.subQuestions && control.subQuestions?.length > 0) {
                        control.subQuestions.forEach((subQMap) => {
                            subQMap.subQuestionIds.forEach((subQ) => {
                                let subQIndex = _questionControls.findIndex(a => a.id == subQ);
                                if (subQIndex != -1) {
                                    const subQcontrol = _questionControls.filter(x => x.id == subQ)[0];
                                    const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == _ques.id));
                                    this.onQuestionControlRemove(subQcontrol, pageNo + 1);
                                    _questionControls.splice(subQIndex, 1);
                                }
                            })

                        });
                    }
                    _questionControls.splice(index, 1);
                    this.onQuestionControlRemove(quesControl, pageNo + 1);
                }
            });
        }


        this.setState({
            sectionControls: sectionControls,
            questionControls: _questionControls
        }, () => this.updateQuestionOrder());
    }

    createQuestionForm = (pageNo: number) => {
        let questionForm = {
            bookmark: `${CustomQuestions.BookMark}[${pageNo}]`,
            pageNo: pageNo,
            formType: FormType.AdditionalQuestions,
            formData: { controlList: [] },
            formGroupId: 0,
            formGuid: Guid.create().toString(),
            formId: 0,
            formName: "",
            enableSignature: false
        };
        return questionForm;
    }

    reinitializeQuestionForm = (document: OrganizerDocument, pageNo: number) => {
        let customQuestionFormgroup = document.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0];
        let index = customQuestionFormgroup.forms.findIndex(form => {
            return Number(pageNo) === form.pageNo
        })
        if (index === -1)
            customQuestionFormgroup.forms.push(this.createQuestionForm(pageNo));
        else {
            customQuestionFormgroup.forms[pageNo - 1].bookmark = `${CustomQuestions.BookMark}[${pageNo}]`;
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList = [];
        }
    }

    updateQuestionOrder = () => {
        const { document, updateDocument } = this.props;
        const { sectionControls, questionControls } = this.state;
        let customQuestionFormgroup = document.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0];
        let capacity = CustomQuestions.InitialCapacity;
        let pageNo = 1;
        let questionNo = 1;
        const pageHeight = PdfProperties.PageHeight - (AdditionalQuestion.HeaderHeight + AdditionalQuestion.FooterHeight);//if its first page , leave some space for header and fill remaining space with questions
        if (customQuestionFormgroup.forms.length <= 0) {
            customQuestionFormgroup.forms.push(this.createQuestionForm(pageNo));
        }
        else {
            if (questionControls.length > 0 || sectionControls.length > 0) {
                customQuestionFormgroup.forms.forEach((form, index) => {
                    (form as AdditionalQuestionsForm).formData.controlList = [];
                    (form as AdditionalQuestionsForm).bookmark = `${CustomQuestions.BookMark}[${index + 1}]`;
                });
            }
            else {
                customQuestionFormgroup.forms = [];
            }
        }
        let top = CustomQuestions.ReducedTop;

        const defaultQuestions = questionControls.filter(x => x.sectionId == Guid.EMPTY && x.parentQuestionId == Guid.EMPTY);
        defaultQuestions.forEach((question) => {
            question.questionNo = questionNo.toString();
            question.textbox.boundingRectangle.top = top;
            question.textbox.boundingRectangle.left = CustomQuestions.defaultQuestion.Left;
            question.textbox.boundingRectangle.width = CustomQuestions.defaultQuestion.Width;
            const questionHeight = question.textbox.boundingRectangle.height;
            const totalHeight = this.getTotalHeight(question);
            if (capacity + totalHeight > pageHeight) {
                capacity = CustomQuestions.InitialCapacity;
                pageNo += 1;
                top = CustomQuestions.DefaultTop;
                question.textbox.boundingRectangle.top = top;
                this.reinitializeQuestionForm(document, pageNo);
            }
            top -= questionHeight;
            if (question.type === QuestionType.ShortAnswer) {
                top = this.getAdjustedTop(top + questionHeight, question.textbox.boundingRectangle.height);
                question.inputTextbox.boundingRectangle.top = top;
                question.inputTextbox.boundingRectangle.width = question.textbox.boundingRectangle.width;
                question.inputTextbox.boundingRectangle.left = question.textbox.boundingRectangle.left + 15;
                top -= question.inputTextbox.boundingRectangle.height;
            }
            else {
                question.radioButtonGroup.controlData.items[0].boundingRectangle.top = RadioButtonProperties.Top;
                question.radioButtonGroup.controlData.items[1].boundingRectangle.top = RadioButtonProperties.Top;
                question.radioButtonGroup.controlData.items[0].boundingRectangle.left = RadioButtonProperties.Left;
                question.radioButtonGroup.controlData.items[1].boundingRectangle.left = RadioButtonProperties.Left + 40;
            }
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(question);
            capacity += totalHeight;
            top -= 10; //space after question
            questionNo++;
            if (question.subQuestions != undefined && question.subQuestions.length > 0) {
                let subquestionIndex = 0;
                question.subQuestions.forEach((subquestion) => {
                    subquestion.subQuestionIds.forEach((id) => {
                        const defaultSubQuestion = questionControls.filter(x => x.id == id)[0];
                        if (defaultSubQuestion) {
                            defaultSubQuestion.questionNo = CustomQuestions.SubQuestionSeries.charAt(subquestionIndex);
                            defaultSubQuestion.textbox.boundingRectangle.top = top;
                            defaultSubQuestion.textbox.boundingRectangle.left = CustomQuestions.defaultSubQuestion.Left;
                            defaultSubQuestion.textbox.boundingRectangle.width = CustomQuestions.defaultSubQuestion.Width;
                            const subQuestionHeight = defaultSubQuestion.textbox.boundingRectangle.height;
                            const totalsubQuestionHeight = this.getTotalHeight(defaultSubQuestion);
                            if (capacity + totalsubQuestionHeight > pageHeight) {
                                capacity = CustomQuestions.InitialCapacity;
                                pageNo += 1;
                                top = CustomQuestions.DefaultTop;
                                defaultSubQuestion.textbox.boundingRectangle.top = top;
                                this.reinitializeQuestionForm(document, pageNo);
                            }
                            top -= subQuestionHeight;
                            if (defaultSubQuestion.type === QuestionType.ShortAnswer) {
                                top = this.getAdjustedTop(top + subQuestionHeight, defaultSubQuestion.textbox.boundingRectangle.height);
                                defaultSubQuestion.inputTextbox.boundingRectangle.left = defaultSubQuestion.textbox.boundingRectangle.left + 15;
                                defaultSubQuestion.inputTextbox.boundingRectangle.width = defaultSubQuestion.textbox.boundingRectangle.width;
                                defaultSubQuestion.inputTextbox.boundingRectangle.top = top;
                                top -= defaultSubQuestion.inputTextbox.boundingRectangle.height;
                            }
                            else {
                                defaultSubQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.subQuestionRadio.YesButtonLeft;
                                defaultSubQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.subQuestionRadio.NoButtonLeft;
                            }
                            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(defaultSubQuestion);
                            capacity += totalsubQuestionHeight;
                            top -= 10; //space after question
                            subquestionIndex++;
                        }
                    })
                })
            }

        });

        sectionControls.forEach((sectionControl) => {
            sectionControl.section.boundingRectangle.top = top;
            sectionControl.section.boundingRectangle.left = 20;
            const customQuestions = questionControls.filter(x => x.sectionId == sectionControl.id && x.parentQuestionId == Guid.EMPTY);
            const totalcustomQuestionHeight = (customQuestions && customQuestions.length > 0) ? this.getTotalHeight(customQuestions[0]) : 0;
            if (capacity + sectionControl.section.boundingRectangle.height + totalcustomQuestionHeight > pageHeight) {
                capacity = 0;
                pageNo += 1;
                top = CustomQuestions.DefaultTop;
                sectionControl.section.boundingRectangle.top = top;
                this.reinitializeQuestionForm(document, pageNo);
            }
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(sectionControl);
            capacity += sectionControl.section.boundingRectangle.height;
            top -= sectionControl.section.boundingRectangle.height;

            customQuestions.forEach((customQuestion) => {
                customQuestion.questionNo = questionNo.toString();
                customQuestion.textbox.boundingRectangle.top = top;
                customQuestion.textbox.boundingRectangle.left = CustomQuestions.customQuestion.Left;
                customQuestion.textbox.boundingRectangle.width = CustomQuestions.customQuestion.Width;
                const customQuestionHeight = customQuestion.textbox.boundingRectangle.height;
                const totalcustomQuestionHeight = this.getTotalHeight(customQuestion);
                if (capacity + totalcustomQuestionHeight > pageHeight) {
                    capacity = CustomQuestions.InitialCapacity;
                    pageNo += 1;
                    top = CustomQuestions.DefaultTop;
                    customQuestion.textbox.boundingRectangle.top = top;
                    this.reinitializeQuestionForm(document, pageNo);
                }
                top -= customQuestionHeight;
                if (customQuestion.type === QuestionType.ShortAnswer) {
                    top = this.getAdjustedTop(top + customQuestionHeight, customQuestion.textbox.boundingRectangle.height);
                    customQuestion.inputTextbox.boundingRectangle.left = customQuestion.textbox.boundingRectangle.left + 15;
                    customQuestion.inputTextbox.boundingRectangle.top = top;// top - customQuestion.textbox.boundingRectangle.height;
                    customQuestion.inputTextbox.boundingRectangle.width = customQuestion.textbox.boundingRectangle.width;
                    top -= customQuestion.inputTextbox.boundingRectangle.height;
                }
                else {
                    customQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.subQuestionRadio.YesButtonLeft;
                    customQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.subQuestionRadio.NoButtonLeft;
                }
                (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(customQuestion);
                capacity += totalcustomQuestionHeight;
                top -= 10;
                questionNo++;
                if (customQuestion.subQuestions != undefined && customQuestion.subQuestions.length > 0) {
                    let customSubquestionIndex = 0;
                    customQuestion.subQuestions.forEach((subquestion) => {
                        subquestion.subQuestionIds.forEach((id) => {
                            const customSubQuestion = questionControls.filter(x => x.id == id)[0];
                            if (customSubQuestion) {
                                customSubQuestion.questionNo = CustomQuestions.SubQuestionSeries.charAt(customSubquestionIndex);
                                customSubQuestion.textbox.boundingRectangle.top = top;
                                customSubQuestion.textbox.boundingRectangle.left = CustomQuestions.customSubQuestion.Left;
                                customSubQuestion.textbox.boundingRectangle.width = CustomQuestions.customSubQuestion.Width;
                                const subQuestionHeight = customSubQuestion.textbox.boundingRectangle.height;
                                const totalsubQuestionHeight = this.getTotalHeight(customSubQuestion);
                                if (capacity + totalsubQuestionHeight > pageHeight) {
                                    capacity = CustomQuestions.InitialCapacity;
                                    pageNo += 1;
                                    top = CustomQuestions.DefaultTop;
                                    customSubQuestion.textbox.boundingRectangle.top = top;
                                    this.reinitializeQuestionForm(document, pageNo);
                                }
                                top -= subQuestionHeight;
                                if (customSubQuestion.type === QuestionType.ShortAnswer) {
                                    top = this.getAdjustedTop(top + subQuestionHeight, customSubQuestion.textbox.boundingRectangle.height);
                                    customSubQuestion.inputTextbox.boundingRectangle.left = customSubQuestion.textbox.boundingRectangle.left + 15;
                                    customSubQuestion.inputTextbox.boundingRectangle.width = customSubQuestion.textbox.boundingRectangle.width;
                                    customSubQuestion.inputTextbox.boundingRectangle.top = top;
                                    top -= customSubQuestion.inputTextbox.boundingRectangle.height;
                                }
                                else {
                                    customSubQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.customSubQuestionRadio.YesButtonLeft;
                                    customSubQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.customSubQuestionRadio.NoButtonLeft;
                                }
                                (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(customSubQuestion);
                                capacity += totalsubQuestionHeight;
                                top -= 10;
                                customSubquestionIndex++;
                            }
                        })
                    })
                }

            });
        })

        customQuestionFormgroup.forms = customQuestionFormgroup.forms.filter(form => {
            return (form as AdditionalQuestionsForm).formData.controlList.length > 0
        })
        updateDocument({ ...document, questionsDocument: null });
    }


    handleTabSelect = (index: any) => {
        this.setState({ activeKey: parseInt(index.toString()) });
    }

    isEsignLetterEnabled = (formGroups: FormGroup[]) => {
        const engagementLetterBookmarks = this.getBookmarks(formGroups, GroupType.Engagement, "Bookmarks");
        const engagementLetterPages = this.getPages(engagementLetterBookmarks);
        const esignOrganizerBookmarks = this.getBookmarks(formGroups, GroupType.OrganizerWithSignature, "Bookmarks");
        const esignOrganizerPages = this.getPages(esignOrganizerBookmarks);
        return engagementLetterPages.length > 0 || esignOrganizerPages.length > 0;
    }

    checkAndUpdateEnagementLetterOption = () => {
        const { formGroups, additionalEsignDocuments } = this.props.document;
        if (!this.isEsignLetterEnabled(formGroups) && additionalEsignDocuments?.length === 0) {
            this.props.document.documentSettings.reminderSettings.esignReminder.enabled = false;
        }
    }

    onSave = () => {

        if (!validateClientName(this.props.document.organizerClients)) {
            return;
        }

        if (!validateLocationData(this.props.document)) {
            return;
        }

        if (!validateEROData(this.props.document)) {
            return;
        }

        if (this.props.document.documentSettings.deliverySettings.contactPersonId === null || this.props.document.documentSettings.deliverySettings.contactPersonId == 0) {
            VenusNotifier.Warning(`Please select contact person`, null);
            return;
        }
        this.setState({
            loading: true,
            actionType: ActionType.SaveAndClose
        });

        this.showLocationMismatchModal(false);
    }

    showLocationMismatchModal = (isFinish: boolean) => {
        let userLocationsData = this.props.userLocations.locations
        let locationValue = this.props.document.locationId

        const locationData = locationValue && userLocationsData.find(location => location === locationValue);

        if (!locationData && locationValue > 0) {
            this.setState({ showLocationMismatchActionModel: true, isLocationMismatch: true });
        }
        else {
            this.setState({ isLocationMismatch: false });

            let callbackFunction = this.saveOrganizerDocument;

            if (isFinish) {
                callbackFunction = this.onShowFinishPopover;
            }

            compareClientInfo(this.getClientInfoModel(), this.editClientInfoResourceId, this.setClientInfoComparisonResult,
                callbackFunction, this.onClientManagementComparisonFailed);
        }
    }

    saveOrganizerDocument = () => {
        this.setState({
            loading: true
        });
        this.removeUnwantedOrganizerControls();
        this.checkAndUpdateEnagementLetterOption();

        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        let organizerDocument: any = {
            processInfo: this.props.document,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM),
            isUpdateUserId: !this.state.isLocationMismatch && this.props.document.createdBy === 0
        }

        this.props.onSave && this.props.onSave(organizerDocument, () => {
            if (this.state.deletedEsignDocuments.length > 0)
                this.props.deleteEsignDocuments(this.state.deletedEsignDocuments);
            this.props.onClose(this.props.document.id);
            this.props.pageReload();
        });
    }

    onFinishProcessing = () => {
        this.setState({
            loading: true,
            actionType: ActionType.Finish
        });
        const { document } = this.props;
        let engagementLetterExists = false;
        const index = document.formGroups.findIndex(formGroup => formGroup.type === GroupType.Engagement);
        if (index !== -1) {
            engagementLetterExists = true;
        }

        let valid = true;
        if (document.organizerClients.every(client => client.isDeceased)) {
            VenusNotifier.Warning(`Cannot deliver organizer, all organizer client(s) are deceased`, null);
            valid = false;
        }

        if (!document.clientId || !document.clientId.trim()) {
            VenusNotifier.Warning(`Please enter Client ID`, null);
            valid = false;
        }

        if (!validateLocationData(document)) {
            valid = false;
        }

        if (!validateEROData(document)) {
            valid = false;
        }

        valid = !document.organizerClients.some(client => !validateOrganizerClient(client));

        if (!validateOrganizerDocumentSettings(document.documentSettings, engagementLetterExists)) {
            valid = false;
        }

        const organizerSignGroup = document
            .formGroups
            .filter(group => group.type == GroupType.OrganizerWithSignature);

        if (
            organizerSignGroup &&
            organizerSignGroup.length
        ) {
            const signRequiredFormNames: string[] = [];

            organizerSignGroup.forEach(group => {
                group.forms.forEach((f) => {
                    const form = f as OrganizerForm;
                    if (!(form?.formData?.controlList?.length ?? false)) {
                        signRequiredFormNames.push(form.bookmark);
                    }
                });
            });

            if (signRequiredFormNames.length) {

                const warningMessage: React.ReactNode = <div>
                    The following Organizer page(s) were selected for signature, but no signature controls have been placed.
                    <br />
                    <br />
                    {
                        signRequiredFormNames.map(form => <li>{form}</li>)
                    }
                </div >;

                VenusNotifier.Warning(warningMessage, null);

                valid = false;

            }
        }

        if (!valid) {
            this.setState({
                loading: false
            });
            return;
        }

        this.showLocationMismatchModal(true);
    }

    onShowFinishPopover = () => {
        this.setState((prevState) => ({
            showFinishPopover: !prevState.showFinishPopover,
            loading: false
        }));
    }

    removeUnwantedOrganizerControls = () => {
        let formGroups = this.props.document.formGroups;
        formGroups.map(formGroup => {
            if (formGroup.type == GroupType.Organizer) {
                formGroup.forms = formGroup.forms.map((form: Form | OrganizerForm) => {
                    return {
                        ...form,
                        formData: {
                            controlList: []
                        }
                    } as OrganizerForm;
                });
            }
        })
        this.props.updateDocument({ ...this.props.document, formGroups: formGroups });
    }

    onDeliver = () => {

        this.setState({
            loading: true
        });

        if (this.props.document.organizerClients.some(client => client.isDeceased)) {
            this.props.document.organizerClients = this.props.document.organizerClients.map(client => {
                if (!client.isDeceased) {
                    client.signingOrder = 1;
                    return client;
                }
                return { ...client, signingOrder: 2 }
            });
            this.props.updateDocument(this.props.document);
        }

        let valid = true;
        this.props.document.organizerClients.map(client => {
            const clientTypeString: string = OrganizerClientType[client.clientType].toString();
            if (client.signingOrder === 1 && (!client.email || !isValidEmailAddress(client.email, true))) {
                VenusNotifier.Warning(`Please enter valid ${clientTypeString} Email Id`, null);
                valid = false;
            }

        });
        if (!valid) {
            this.setState({
                loading: false
            });
            return;
        }
        this.removeUnwantedOrganizerControls();
        document.body.click();
        this.checkAndUpdateEnagementLetterOption();

        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        var locationName = this.state.locationsState.find(r => r.value == this.props.document.locationId)?.label;

        let organizerDocument: any = {
            processInfo: this.props.document,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM),
            isUpdateUserId: !this.state.isLocationMismatch && this.props.document.createdBy === 0,
            locationName: locationName == undefined ? '' : locationName
        }
        if (this.state.deletedEsignDocuments.length > 0) {
            this.props.deleteEsignDocuments(this.state.deletedEsignDocuments, () => {
                this.setState({ deletedEsignDocuments: [] });
                this.props.onFinish && this.props.onFinish(organizerDocument, this.props.onClose);
            });
        }
        else {
            this.props.onFinish && this.props.onFinish(organizerDocument, this.props.onClose);
        }
    }

    validateEngagementLetter = (formGroups: FormGroup[],
        taxpayer: OrganizerClient | undefined,
        spouse: OrganizerClient | undefined) => {
        const signatureControls = this.getSignatureControls(formGroups, GroupType.Engagement);
        const signatureControlstoArray: any = Object.keys(signatureControls).map((key: any) => signatureControls[key]);

        if (taxpayer && !taxpayer.isDeceased &&
            this.filterSignatureByRole(signatureControlstoArray, "controlRole", ControlRole.Taxpayer)
                .filter((i: any) => i.controlType == ControlType.Signature)
                .length <= 0) {
            VenusNotifier.Warning(`There should be at least one signature control for the taxpayer to sign.`, null);
            return false;
        }

        if (spouse && !spouse.isDeceased &&
            this.filterSignatureByRole(signatureControlstoArray, "controlRole", ControlRole.Spouse)
                .filter((i: any) => i.controlType == ControlType.Signature)
                .length <= 0) {
            VenusNotifier.Warning(`There should be at least one signature control for the spouse to sign.`, null);
            return false;
        }
        return true;
    }

    filterSignatureByRole = (signatureControls: any, prop: string, role: ControlRole) => {
        var filtered = [];
        if (role) {
            for (var i = 0; i < signatureControls.length; i++) {
                var obj = signatureControls[i];
                for (var key in obj) {
                    if (typeof (obj[key] == "object")) {
                        var item = obj[key];
                        if (item[prop] == role) {
                            filtered.push(item);
                        }
                    }
                }
            }
        }
        return filtered;
    }

    onPrevious = (engagementLetterExists: boolean) => {
        let { activeKey } = this.state;
        const { batchView } = this.props;
        if (activeKey === OrganizerTabs.Organizer) {
            if (engagementLetterExists) {
                activeKey = OrganizerTabs.EngagementLetter
            } else {
                activeKey = OrganizerTabs.ClientInfo
            }
        } else {
            if ((activeKey - 1 === OrganizerTabs.Removed) && batchView) {
                activeKey -= 2;
            } else {
                activeKey -= 1;
            }
        }
        this.setState({ activeKey: activeKey });
    }

    onNext = (engagementLetterExists: boolean) => {
        let { activeKey } = this.state;
        const { batchView } = this.props;
        if (activeKey === OrganizerTabs.ClientInfo) {
            if (engagementLetterExists) {
                activeKey = OrganizerTabs.EngagementLetter
            } else {
                activeKey = OrganizerTabs.Organizer
            }
        } else {
            if ((activeKey + 1 === OrganizerTabs.Removed) && batchView) {
                activeKey += 2;
            } else {
                activeKey += 1;
            }
        }
        this.setState({ activeKey: activeKey });
    }

    moveToGroup = (sourceGroup: GroupType, destinationGroup: GroupType, pageNo: number) => {
        try {
            const isOrganizerSwitch = (sourceGroup === GroupType.Organizer && destinationGroup == GroupType.OrganizerWithSignature) || (sourceGroup === GroupType.OrganizerWithSignature && destinationGroup == GroupType.Organizer);
            let { formGroups } = this.props.document;
            if (sourceGroup === GroupType.Engagement) {
                const engagementGroup = formGroups.find(group => group.type == GroupType.Engagement);
                if (engagementGroup && engagementGroup.forms.length <= 1) {
                    VenusNotifier.Warning("There should be at least one page in Engagement Letter", null);
                    return;
                }
                var SignatureControls = this.getSignatureControls(formGroups, GroupType.Engagement);
                let itemThis = this;
                if (destinationGroup == GroupType.Removed && SignatureControls[pageNo].length > 0 && this.props.readOnlyView) {

                    bootbox.dialog({
                        title: EngagementLetterControlDeleteWarning.DialogBox.Title,
                        message: this.props.document.isBatchTemplate ? EngagementLetterControlDeleteWarning.DialogBox.MessageForTemplate : EngagementLetterControlDeleteWarning.DialogBox.Message,
                        buttons: {
                            cancel: {
                                label: '<i class="glyphicon glyphicon-remove"></i>' + EngagementLetterControlDeleteWarning.DialogBox.Cancel,
                                className: 'btn-white',
                                callback: function () { return }
                            },
                            confirm: {
                                label: '<i class="glyphicon glyphicon-ok"></i>' + EngagementLetterControlDeleteWarning.DialogBox.Confirm,
                                className: 'btn-info',
                                callback: function () { itemThis.onMoveToGroup(sourceGroup, destinationGroup, pageNo); }
                            }
                        }
                    });
                    return;
                }
            }
            if (sourceGroup === GroupType.Organizer) {
                const organizerGroup = formGroups.find(group => group.type == GroupType.Organizer);
                if (organizerGroup && organizerGroup.forms.length <= 1) {
                    VenusNotifier.Warning("There should be at least one page in Organizer", null);
                    return;
                }
            }

            let actualForm: Form | EngagementLetterForm | OrganizerForm | undefined;
            formGroups = formGroups.map(formGroup => {
                if (formGroup.type == sourceGroup) {
                    actualForm = formGroup.forms.find(form => form.pageNo === pageNo);
                    formGroup.forms = formGroup.forms.filter(form => form.pageNo !== pageNo);
                }
                return formGroup;
            });

            if ((sourceGroup == GroupType.Engagement || sourceGroup == GroupType.Removed) && destinationGroup == GroupType.Organizer) {
                let tempForm = actualForm as Form;
                destinationGroup = tempForm.enableSignature ? GroupType.OrganizerWithSignature : GroupType.Organizer;
            }

            const destinationFormGroup = formGroups.find(formGroup => formGroup.type == destinationGroup);
            if (!destinationFormGroup)
                formGroups.push({
                    id: 0,
                    processInfoId: 0,
                    name: '',
                    type: destinationGroup,
                    status: FormGroupStatus.None,
                    forms: []
                });

            if (actualForm) {
                let form: Form;
                switch (destinationGroup) {
                    case GroupType.Engagement:
                        form = {
                            ...actualForm,
                            formType: FormType.EngagementLetter,
                            formData: {
                                controlList: []
                            }
                        } as EngagementLetterForm
                        break;
                    case GroupType.Organizer:
                        form = isOrganizerSwitch ?
                            {
                                ...actualForm,
                                formType: FormType.Organizer,
                                enableSignature: false
                            } as OrganizerForm :
                            {
                                ...actualForm,
                                enableSignature: false,
                                formType: FormType.Organizer,
                                formData: {
                                    controlList: []
                                }
                            } as OrganizerForm
                        break;
                    case GroupType.OrganizerWithSignature:
                        form = isOrganizerSwitch ?
                            {
                                ...actualForm,
                                formType: FormType.OrganizerWithSign,
                                enableSignature: true
                            } as OrganizerForm :
                            {
                                ...actualForm,
                                enableSignature: true,
                                formType: FormType.OrganizerWithSign,
                                formData: {
                                    controlList: []
                                }
                            } as OrganizerForm
                        break;
                    case GroupType.Removed:
                        form = {
                            ...actualForm,
                            formType: FormType.Removed
                        }
                        break;
                }
                formGroups.map(formGroup => {
                    if (formGroup.type == destinationGroup) {
                        formGroup.forms.push(form);
                    }
                })
                this.props.updateDocument({ ...this.props.document, formGroups: formGroups });
                !isOrganizerSwitch && VenusNotifier.Success("Page moved successfully.", null);
            }
        }
        catch (error) {
            VenusNotifier.Success("Page moving failed.", null);
        }
    }

    onMoveToGroup = (sourceGroup: GroupType, destinationGroup: GroupType, pageNo: number) => {
        try {
            let { formGroups } = this.props.document;
            const destinationFormGroup = formGroups.find(formGroup => formGroup.type == destinationGroup);
            if (!destinationFormGroup)
                formGroups.push({
                    id: 0,
                    processInfoId: 0,
                    name: '',
                    type: destinationGroup,
                    status: FormGroupStatus.None,
                    forms: []
                });
            let actualForm: Form | EngagementLetterForm | OrganizerForm | undefined;
            formGroups = formGroups.map(formGroup => {
                if (formGroup.type == sourceGroup) {
                    actualForm = formGroup.forms.find(form => form.pageNo === pageNo);
                    formGroup.forms = formGroup.forms.filter(form => form.pageNo !== pageNo);
                }
                return formGroup;
            });
            if (actualForm) {
                let form: Form;
                switch (destinationGroup) {
                    case GroupType.Engagement:
                        form = {
                            ...actualForm,
                            formType: FormType.EngagementLetter,
                            formData: {
                                controlList: []
                            }
                        } as EngagementLetterForm
                        break;
                    case GroupType.Organizer:
                        form = {
                            ...actualForm,
                            formType: FormType.Organizer
                        }
                        break;
                    case GroupType.Removed:
                        form = {
                            ...actualForm,
                            formType: FormType.Removed
                        }
                        break;
                }
                formGroups.map(formGroup => {
                    if (formGroup.type == destinationGroup) {
                        formGroup.forms.push(form);
                    }
                })
                this.props.updateDocument({ ...this.props.document, formGroups: formGroups });
                VenusNotifier.Success("Page moved successfully.", null);
            }
        }
        catch (error) {
            VenusNotifier.Success("Page moving failed.", null);
        }
    }

    getBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (index === -1) {
            return {
                heading: "",
                pages: []
            }
        }
        const bookmarkPages = formGroups[index].forms.map(form => {
            return {
                pageNo: form.pageNo,
                pageTitle: form.bookmark
            } as BookmarkPage
        });

        return {
            heading: heading,
            pages: bookmarkPages
        }
    }

    getOrganizerBookmarks = (formGroups: FormGroup[], heading: string): OrganizerBookmarks => {
        let bookmarks: OrganizerBookmarkPage[] = []
        const organizerBookmarks = this.getOrganizerBookmarksByType(formGroups, GroupType.Organizer);
        const organizerWithSignBookmarks = this.getOrganizerBookmarksByType(formGroups, GroupType.OrganizerWithSignature);
        bookmarks = organizerBookmarks.concat(organizerWithSignBookmarks);
        bookmarks.sort((a: OrganizerBookmarkPage, b: OrganizerBookmarkPage) => {
            return a.pageNo - b.pageNo;
        })
        return bookmarks.length == 0 ? {
            heading: "",
            pages: []
        } : {
            heading: heading,
            pages: bookmarks
        }
    }

    getOrganizerBookmarksByType = (formGroups: FormGroup[], type: GroupType): OrganizerBookmarkPage[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (index === -1) {
            return []
        }
        const bookmarkPages = formGroups[index].forms.map(form => {
            const organizerform = form as OrganizerForm;
            return {
                pageNo: form.pageNo,
                pageTitle: form.bookmark,
                enabledSourceDocument: this.props.sourceDocumentEnabled && organizerform.enabledSourceDocument,
                enableSignature: organizerform.enableSignature

            } as OrganizerBookmarkPage
        });
        return bookmarkPages;
    }

    getPages = (bookmarks: Bookmarks): number[] => {
        return bookmarks.pages.map(page => page.pageNo);
    }

    getSignatureControls = (formGroups: FormGroup[], type: GroupType): SignatureControlsDictionary => {
        let controls: SignatureControlsDictionary = {};

        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        if (formGroups[index] && formGroups[index].forms) {
            formGroups[index].forms.map((f: Form) => {
                const form: EngagementLetterForm | OrganizerForm = type == GroupType.Engagement ? f as EngagementLetterForm : f as OrganizerForm;
                if (!form.formData) {
                    form.formData = { controlList: [] };
                }
                controls[form.pageNo] = form.formData.controlList;
            });
        }
        return controls;
    }

    getQuestionControls = (formGroups: FormGroup[], type: GroupType): SignatureControlsDictionary => {
        let controls: SignatureControlsDictionary = {};

        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (formGroups[index] && formGroups[index].forms) {
            formGroups[index].forms.map((f: Form) => {
                const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
                if (!form.formData) {
                    form.formData = { controlList: [] };
                }
                controls[form.pageNo] = form.formData.controlList;
                this.addQuestionNumber(controls);
            });
        }
        return controls;
    }

    addQuestionNumber = (controlsDictionary: SignatureControlsDictionary) => {
        let _questionNumber = 1;
        let _subQIndex = 0;
        Object.entries(controlsDictionary).forEach(([page, controls]) => {
            if (controls) {
                controls.forEach((control: Control) => {
                    if (control.controlType == ControlType.QuestionControl) {
                        const _question = control as QuestionControl;
                        if (_question.parentQuestionId == Guid.EMPTY) {
                            _question.questionNo = _questionNumber.toString();
                            _questionNumber++;
                            _subQIndex = 0;
                        }
                        else {
                            _question.questionNo = CustomQuestions.SubQuestionSeries.charAt(_subQIndex++);
                        }
                    }
                });
            }
        });
    }

    addSignatureControl = (formGroups: FormGroup[], type: GroupType, control: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm | OrganizerForm = type == GroupType.Engagement ? f as EngagementLetterForm : f as OrganizerForm;
            const found = form.pageNo === pageNo;
            if (found) {
                if (!form.formData || !form.formData.controlList) {
                    form.formData = { controlList: [] }
                }
                form.formData.controlList.push(control);
            }
        });
        return formGroups;
    }

    addSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, control: Control, pageNo: number): AdditionalEsignDocumentControls => {
        if (!documentControls) {
            documentControls = {} as AdditionalEsignDocumentControls;
            documentControls.formData = [];
            let controlList = [];
            controlList.push(control);
            documentControls.formData.push({
                pageNo: pageNo,
                controlList: controlList
            } as AdditionalEsignDocumentControlData)
        }
        else {
            var index = documentControls.formData.findIndex(a => a.pageNo == pageNo);
            if (index == -1) {
                let controlList = [];
                controlList.push(control);
                documentControls.formData.push({
                    pageNo: pageNo,
                    controlList: controlList
                } as AdditionalEsignDocumentControlData)
            }
            else {
                if (documentControls.formData[index].controlList.every(c => c.id != control.id))
                    documentControls.formData[index].controlList.push(control);
            }
        }
        documentControls?.formData?.sort((a, b) => a.pageNo - b.pageNo);
        return documentControls;
    }

    removeSignatureControl = (formGroups: FormGroup[], type: GroupType, control: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm | OrganizerForm = type == GroupType.Engagement ? f as EngagementLetterForm : f as OrganizerForm;
            const found = form.pageNo === pageNo;
            if (found && form.formData && form.formData.controlList) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id !== control.id);
            }
        });
        return formGroups;
    }

    removeSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, control: Control, pageNo: number): AdditionalEsignDocumentControls => {
        const requiredPage = documentControls.formData.find(a => a.pageNo == pageNo);
        if (requiredPage)
            requiredPage.controlList = requiredPage.controlList.filter(a => a.id != control.id);
        return documentControls;
    }

    replaceSignatureControl = (formGroups: FormGroup[], type: GroupType, oldControl: Control, newControl: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm | OrganizerForm = type == GroupType.Engagement ? f as EngagementLetterForm : f as OrganizerForm;
            const found = form.pageNo === pageNo;
            if (found) {
                for (let i = 0; i < form.formData.controlList.length; i++) {
                    const signControl = form.formData.controlList[i];
                    if (signControl.id === oldControl.id) {
                        signControl.boundingRectangle.left = newControl.boundingRectangle.left;
                        signControl.boundingRectangle.top = newControl.boundingRectangle.top;
                        signControl.controlType = newControl.controlType;
                        break;
                    }
                }
            }
        });
        return formGroups;
    }

    replaceSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, oldControl: Control, newControl: Control, pageNo: number): AdditionalEsignDocumentControls => {
        const requiredPage = documentControls.formData.find(a => a.pageNo == pageNo);
        if (requiredPage) {
            let requiredControl = requiredPage.controlList.find(a => a.id == oldControl.id);
            if (requiredControl) {
                requiredControl.boundingRectangle.left = newControl.boundingRectangle.left;
                requiredControl.boundingRectangle.top = newControl.boundingRectangle.top;
                requiredControl.controlType = newControl.controlType;
            }
        }
        return documentControls;
    }

    onEngagementLetterControlDrop = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.addSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onOrganizerSignPageControlDrop = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.addSignatureControl(formGroups, GroupType.OrganizerWithSignature, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onEngagementLetterControlRemove = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.removeSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onOrganizerSignPageControlRemove = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.removeSignatureControl(formGroups, GroupType.OrganizerWithSignature, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onEngagementLetterControlUpdate = (oldControl: Control, newControl: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.replaceSignatureControl(formGroups, GroupType.Engagement, oldControl, newControl, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onOrganizerSignPageControlUpdate = (oldControl: Control, newControl: Control, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.replaceSignatureControl(formGroups, GroupType.OrganizerWithSignature, oldControl, newControl, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onAdditionalEsignControlDrop = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.addSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onAdditionalEsignSaveToolTip = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.addSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        let pageIndex = additionalEsignDocuments[index].documentControls.formData.findIndex(a => a.pageNo == pageNo);
        let documentControls = additionalEsignDocuments[index].documentControls.formData[pageIndex].controlList;
        for (let i = 0; i < documentControls.length; i++) {
            let documentControl = documentControls[i];
            if (documentControl.id === control.id) {
                if (control.controlType == ControlType.SignatureRadioButtonControl) {
                    let radioButtonControl = documentControl as SignatureRadioButtonControl;
                    if (radioButtonControl.items.length != Number(control.tooltip)) {
                        radioButtonControl.items = this.addControlItems(control.tooltip);
                    }
                    radioButtonControl.required = control.required;
                    radioButtonControl.tooltip = control.tooltip;
                    documentControl = radioButtonControl;
                    break;
                }
                else if (control.controlType == ControlType.SignatureCheckBoxControl) {
                    let checkBoxControl = documentControl as SignatureCheckBoxControl;
                    if (checkBoxControl.items.length != Number(control.tooltip)) {
                        checkBoxControl.items = this.addControlItems(control.tooltip);
                    }
                    checkBoxControl.required = control.required;
                    checkBoxControl.tooltip = control.tooltip;
                    documentControl = checkBoxControl;
                    break;
                }
                else {
                    documentControl.tooltip = control.tooltip;
                    documentControl.required = control.required;
                    break;
                }
            }
        }
        this.props.updateDocument(this.props.document);
    }

    onOrganizerSignPageSaveToolTip = (control: Control, pageNo: number) => {
        let formGroups = [...this.props.document.formGroups]

        if (control.controlType == ControlType.SignatureRadioButtonControl) {
            let radioButtonControl = control as SignatureRadioButtonControl;
            if (radioButtonControl.items.length != Number(control.tooltip)) {
                radioButtonControl.items = this.addControlItems(control.tooltip);
            }
            radioButtonControl.required = control.required;
            radioButtonControl.tooltip = control.tooltip;
            const fgroups = this.removeSignatureControl(formGroups, GroupType.OrganizerWithSignature, control, pageNo);
            this.props.document.formGroups = this.addSignatureControl(fgroups, GroupType.OrganizerWithSignature, radioButtonControl, pageNo);
        }
        else if (control.controlType == ControlType.SignatureCheckBoxControl) {
            let checkBoxControl = control as SignatureCheckBoxControl;
            if (checkBoxControl.items.length != Number(control.tooltip)) {
                checkBoxControl.items = this.addControlItems(control.tooltip);
            }
            checkBoxControl.required = control.required;
            checkBoxControl.tooltip = control.tooltip;
            const fgroups = this.removeSignatureControl(formGroups, GroupType.OrganizerWithSignature, control, pageNo);
            this.props.document.formGroups = this.addSignatureControl(fgroups, GroupType.OrganizerWithSignature, checkBoxControl, pageNo);
        }
        else {
            control.tooltip = control.tooltip;
            control.required = control.required;
            const fgroups = this.removeSignatureControl(formGroups, GroupType.OrganizerWithSignature, control, pageNo);
            this.props.document.formGroups = this.addSignatureControl(fgroups, GroupType.OrganizerWithSignature, control, pageNo);
        }
        this.props.updateDocument(this.props.document);
    }

    onELPageSaveToolTip = (control: Control, pageNo: number) => {
        const formGroups = [...this.props.document.formGroups]
        const fGroups = this.removeSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.document.formGroups = this.addSignatureControl(fGroups, GroupType.Engagement, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    private addControlItems = (noofcontrols: any) => {
        let items: any[] = [];
        let controlLeft = 20;
        const name = Guid.create().toString();
        if (noofcontrols) {
            for (let i = 0; i < Number(noofcontrols); i++) {
                let data = ChoosableControlData.createNullObject();
                data.left = (controlLeft * (i + 1));
                data.top = 2;
                data.id = Guid.create().toString();
                data.name = name;
                items.push(data);
            }
        }
        return items;
    }

    onAdditionalEsignControlRemove = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.removeSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        this.props.updateDocument(this.props.document);
        this.forceUpdate();
    }

    onAdditionalEsignControlUpdate = (oldControl: Control, newControl: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.replaceSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, oldControl, newControl, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onAdditionalEsignUpdateControlData = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        let pageIndex = additionalEsignDocuments[index].documentControls.formData.findIndex(a => a.pageNo == pageNo);
        let documentControls = additionalEsignDocuments[index].documentControls.formData[pageIndex].controlList;
        for (let i = 0; i < documentControls.length; i++) {
            let documentControl = documentControls[i];
            if (documentControl.id === control.id) {
                documentControl.boundingRectangle.left = control.boundingRectangle.left;
                documentControl.boundingRectangle.top = control.boundingRectangle.top;
                if (control.controlType == ControlType.SignatureRadioButtonControl) {
                    let radioButtonControl = control as SignatureRadioButtonControl;
                    documentControl = radioButtonControl;
                    documentControl.boundingRectangle.left = control.boundingRectangle.left;
                    documentControl.boundingRectangle.top = control.boundingRectangle.top;
                }
                else if (control.controlType == ControlType.SignatureCheckBoxControl) {
                    let checkBoxControl = documentControl as SignatureCheckBoxControl;
                    documentControl = checkBoxControl;
                    documentControl.boundingRectangle.left = control.boundingRectangle.left;
                    documentControl.boundingRectangle.top = control.boundingRectangle.top;
                }
            }
        }
        this.props.updateDocument(this.props.document);
    }

    addQuestionControl = (formGroups: FormGroup[], type: GroupType, control: QuestionControl, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        formGroups[index].forms.map((f: Form) => {
            const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
            const found = form.pageNo === pageNo;
            if (found) {
                if (!form.formData) {
                    form.formData = { controlList: [] }
                }
                form.formData.controlList.push(control);
            }
        });
        return formGroups;
    }

    removeQuestionControl = (formGroups: FormGroup[], type: GroupType, control: QuestionControl, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        formGroups[index].forms.map((f: Form) => {
            const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
            const found = form.pageNo === pageNo;
            if (found) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id != control.id);
            }
        });
        return formGroups;
    }

    removeSectionControl = (formGroups: FormGroup[], type: GroupType, control: SectionControl, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        formGroups[index].forms.map((f: Form) => {
            const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
            const found = form.pageNo === pageNo;
            if (found) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id != control.id);
            }
        });
        return formGroups;
    }

    onQuestionControlDrop = (control: QuestionControl, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.addQuestionControl(formGroups, GroupType.AdditionalQuestions, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onQuestionControlRemove = (control: QuestionControl, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.removeQuestionControl(formGroups, GroupType.AdditionalQuestions, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    onSectionControlRemove = (control: SectionControl, pageNo: number) => {
        const { formGroups } = this.props.document;
        this.props.document.formGroups = this.removeSectionControl(formGroups, GroupType.AdditionalQuestions, control, pageNo);
        this.props.updateDocument(this.props.document);
    }

    removeQuestionPage = (pageNo: number) => {
        const { document, document: { formGroups, questionsDocument }, updateDocument } = this.props;
        const index = formGroups.findIndex(group => group.type == GroupType.AdditionalQuestions);
        const documentFacade: IPdfDocumentFacade = questionsDocument;
        documentFacade.removePages([pageNo]);
        formGroups[index].forms = formGroups[index].forms.filter(form => form.pageNo !== pageNo);
        document.formGroups = [...formGroups];
        updateDocument({ ...document, questionsDocument: documentFacade });
    }

    updateDocumentSettings = (settings: OrganizerDocumentSettings) => {
        this.props.updateDocument({ ...this.props.document, documentSettings: settings });
    }

    updateClients = (clients: OrganizerClient[]) => {
        this.props.updateDocument({ ...this.props.document, organizerClients: clients });
    }

    generateTaxpayerView = (callback: () => void) => {
        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        let organizerDocument: any = {
            processInfo: this.props.document,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM)
        }
        if (this.state.deletedEsignDocuments.length > 0) {
            this.props.deleteEsignDocuments(this.state.deletedEsignDocuments, () => {
                this.setState({ deletedEsignDocuments: [] });
                this.props.generateTaxpayerView(organizerDocument, callback);
            });
        }
        else {
            this.props.generateTaxpayerView(organizerDocument, callback);
        }
    }

    getTotalHeight = (question: QuestionControl) => {
        return (question.type == QuestionType.YesNo) ? question.textbox.boundingRectangle.height + 10 :
            question.textbox.boundingRectangle.height + question.inputTextbox.boundingRectangle.height + 5;
    }

    getAdjustedTop = (top: number, height: number) => {
        let heightDiff = (height * 0.1);
        top = top - height + heightDiff;
        return top;
    }

    submitAdditionalEsignDocs = (data: AdditionalEsignModel[]) => {
        let { additionalEsignDocuments } = this.props.document;
        this.props.document.additionalEsignDocuments = [...additionalEsignDocuments, ...data];
        this.props.updateDocument(this.props.document);
    }

    updateAdditionalEsignDocument = (data: AdditionalEsignModel) => {
        let { additionalEsignDocuments } = this.props.document;
        this.props.document.additionalEsignDocuments = additionalEsignDocuments.map((doc) => {
            if (data.fileGuid == doc.fileGuid) {
                //data.uploadedBy = this.props.loggedInCPA;
                //data.uploadedOn = moment.utc(new Date()).format() as any
                return data;
            }
            return doc;
        })
        this.props.updateDocument(this.props.document);
    }

    deleteAdditionalEsignDocument = (fileGuid: string) => {
        let { additionalEsignDocuments } = this.props.document;
        let { deletedEsignDocuments } = this.state;
        const deletedDoc = additionalEsignDocuments.find(a => a.fileGuid == fileGuid);
        if (deletedDoc?.id && deletedDoc.id > 0) {
            this.setState({
                deletedEsignDocuments: [...deletedEsignDocuments, deletedDoc.id]
            });
        }
        this.props.deleteBlobAdditionalEsign(fileGuid);
        this.props.document.additionalEsignDocuments = additionalEsignDocuments.filter(a => a.fileGuid != fileGuid);
        this.props.updateDocument(this.props.document);
    }

    updateEroSignatureStamp = (userId: number) => {
        this.props.document.documentSettings.signatureSettings.signatureStampUser = userId;
        this.props.updateDocument(this.props.document);
    }

    updateSignatureStatus = (pageNumber: number, enableSignature: boolean) => {
        let formGroups = this.props.document.formGroups;
        const index = enableSignature ? formGroups.findIndex(formGroup => formGroup.type === GroupType.Organizer) : formGroups.findIndex(formGroup => formGroup.type === GroupType.OrganizerWithSignature);
        if (index === -1) {
            return;
        }
        let formIndex = formGroups[index].forms.findIndex(x => x.pageNo == pageNumber)
        let form = formGroups[index].forms[formIndex] as OrganizerForm
        if (form) {
            if (enableSignature) {
                this.moveToGroup(GroupType.Organizer, GroupType.OrganizerWithSignature, pageNumber);
            } else {
                this.moveToGroup(GroupType.OrganizerWithSignature, GroupType.Organizer, pageNumber);
            }
        }
    }

    private getLocationName = () => {

        const index = this.state.locationsState.findIndex(
            (x) => x.value == this.props.document.locationId
        );

        if (index != -1) {
            return this.state.locationsState[index].label;
        }

        return "";
    };

    private onContinueLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false }, () => {
            compareClientInfo(this.getClientInfoModel(), this.editClientInfoResourceId, this.setClientInfoComparisonResult,
                this.onShowFinishPopover,
                this.onClientManagementComparisonFailed);
        });
    };

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ loading: false, showLocationMismatchActionModel: false });
    };

    private initializePDFDetail() {
        if (this.props.document.document && this.props.document.document.parts[0]?.fileName && (this.state.pdfURL == '' || this.state.pdfData == '')) {
            this.setState({
                pdfURL: this.props.document.document.parts[0].fileName
            });
            if (this.state.pdfData == '')
                this.getBase64(this.props.document.document.parts[0].fileName);
        }
    }

    public render() {

        const { activeKey, loading, questionPage, pdfData, pdfURL } = this.state;
        const { document, order, onClose, company,
            preparerMessages, users, isBatchOrganizer,
            company: { companyProfile: { countryStates } },
            document: { formGroups },
            companySettings, updateDocument, readOnlyView, showSignaturePanel, getUploadLink,
            requestDocuments, deleteBlobAdditionalEsign, additionalEsignReadOnly, batchView,
            isCustomQuestionEditable
        } = this.props;

        const finishPopover = (<Popover id="finish-popover"
            className="finishPopover"
            style={{ width: "550px", height: "300px", top: "527px", fontSize: '12px' }}>
            <Popover.Title as="h3" style={{ fontSize: '14px' }}>Finish Organizer Processing</Popover.Title>
            <Popover.Content>
                <FinishPopoverComponent
                    deliver={this.onDeliver}
                    loading={loading}
                    model={document}
                    updateDocument={updateDocument}
                    isTpMfaEnabled={company.commonSettings ? company.commonSettings.mfaSetting.iSTPMfaEnabled : false}
                />
            </Popover.Content>
        </Popover>);

        const organizerBookmarks = this.getOrganizerBookmarks(formGroups, "Bookmarks");
        const clientInfoBookmarks = this.getBookmarks(formGroups, GroupType.None, "Bookmarks");
        const removedBookmarks = this.getBookmarks(formGroups, GroupType.Removed, "Bookmarks");
        const engagementLetterBookmarks = this.getBookmarks(formGroups, GroupType.Engagement, "Bookmarks");
        const engagementLetterPages = this.getPages(engagementLetterBookmarks);
        const removedPages = this.getPages(removedBookmarks);
        const engagementExists = engagementLetterPages.length > 0;
        const additionalFileExists = this.props.document.additionalEsignDocuments.length > 0

        return (<CustomWindow key={`custom-organizer-window-${document.id}`}
            hideClosePopover={readOnlyView}
            onClose={() => onClose(document.id)}
            title={document.clientId}
            order={order}
            disableMaximize={true}
            disableMinimize={true}
            disableFullscreen={false}
            alwaysFitToParent={true}
            closeMessage={closeOrganizerProcessMessage}
            onResize={() => { this.setState({ renderTrigger: Guid.create().toString() }) }}>
            {
                (<CustomWindowBody isBusy={loading}>
                    <Tabs
                        activeKey={activeKey}
                        onSelect={this.handleTabSelect}
                        id="process-return-groups"
                        fill>

                        <Tabs eventKey={OrganizerTabs.ClientInfo}
                            title={<div className="overflowText">Client Info</div>}
                            className="zIndex10">
                            {(activeKey == OrganizerTabs.ClientInfo) &&
                                <ClientInfoTab
                                    model={document}
                                    states={countryStates}
                                    updateDocument={updateDocument}
                                    pages={this.getPages(clientInfoBookmarks)}
                                    readOnlyView={readOnlyView}
                                    pdfURL={this.state.pdfURL}
                                    preFillInfoDetails={this.state.prefilledClientInfoDetails}
                                    onResetPrefillInfoDetails={this.resetPrefillInfoDetails}
                                    locations={this.state.locationsState}
                                    eros={this.state.erosState}
                                />
                            }

                        </Tabs>
                        {
                            engagementExists &&
                            <Tabs eventKey={OrganizerTabs.EngagementLetter}
                                title={<div className="overflowText">Engagement Letter</div>}
                                className="zIndex10">
                                {
                                    (activeKey === OrganizerTabs.EngagementLetter) &&
                                    <EngagementLetterTab
                                        document={document}
                                        pdf={document.document}
                                        organizerClients={document.organizerClients}
                                        moveToGroup={(dest: GroupType, page: number) => this.moveToGroup(GroupType.Engagement, dest, page)}
                                        bookmarks={engagementLetterBookmarks}
                                        pages={this.getPages(engagementLetterBookmarks)}
                                        onDrop={this.onEngagementLetterControlDrop}
                                        onControlRemove={this.onEngagementLetterControlRemove}
                                        onControlUpdate={this.onEngagementLetterControlUpdate}
                                        signatureControls={this.getSignatureControls(formGroups, GroupType.Engagement)}
                                        readOnlyView={readOnlyView}
                                        pdfURL={pdfURL}
                                        showSignaturePanel={showSignaturePanel}
                                        onSaveToolTip={this.onELPageSaveToolTip}
                                    />
                                }
                            </Tabs>
                        }
                        <Tabs eventKey={OrganizerTabs.Organizer}
                            title={<div className="overflowText">Organizer</div>}
                            className="zIndex10">
                            {
                                (activeKey === OrganizerTabs.Organizer) &&
                                <OrganizerTab
                                    pdf={document.document}
                                    moveToGroup={(source: GroupType, dest: GroupType, page: number) => this.moveToGroup(source, dest, page)}
                                    bookmarks={organizerBookmarks}
                                    pages={this.getPages(organizerBookmarks)}
                                    engagementLetterExists={engagementExists}
                                    readOnlyView={readOnlyView}
                                    pdfURL={pdfURL}
                                    sourceDocumentEnabled={this.props.sourceDocumentEnabled}
                                    updatesignatureStatus={this.updateSignatureStatus}
                                    organizerClients={document.organizerClients}
                                    company={this.props.company}
                                    isAssignedToLoggedinUser={true}
                                    userProfile={this.props.userBasicProfile}
                                    userSignatures={this.props.userSignatures}
                                    userSettings={this.props.userSettings}
                                    ero={document.ero}
                                    requestDownloadPath={this.props.requestDownloadPath}
                                    users={this.props.users}
                                    signatureSettings={document.documentSettings.signatureSettings}
                                    isBatchOrganizer={isBatchOrganizer ?? false}
                                    signatureControls={this.getSignatureControls(formGroups, GroupType.OrganizerWithSignature)}
                                    onDrop={this.onOrganizerSignPageControlDrop}
                                    onControlRemove={this.onOrganizerSignPageControlRemove}
                                    onControlUpdate={this.onOrganizerSignPageControlUpdate}
                                    onSaveToolTip={this.onOrganizerSignPageSaveToolTip}
                                    partners={this.props.partners}
                                />
                            }
                        </Tabs>

                        <Tabs eventKey={OrganizerTabs.AdditionalESign}
                            title={<div className="overflowText">Additional E-Sign Documents</div>}
                            className="zIndex10">
                            {
                                (activeKey === OrganizerTabs.AdditionalESign) &&
                                <AdditionalEsignTab
                                    additionalEsignDocuments={document.additionalEsignDocuments}
                                    organizerClients={document.organizerClients}
                                    bookmarks={engagementLetterBookmarks}
                                    onDrop={this.onAdditionalEsignControlDrop}
                                    onSaveToolTip={this.onAdditionalEsignSaveToolTip}
                                    onUpdateControlData={this.onAdditionalEsignUpdateControlData}
                                    onControlRemove={this.onAdditionalEsignControlRemove}
                                    onControlUpdate={this.onAdditionalEsignControlUpdate}
                                    readOnlyView={readOnlyView}
                                    pdfURL={document.additionalEsignDocuments && document.additionalEsignDocuments[0]?.sasURL}
                                    showSignaturePanel={showSignaturePanel}
                                    getUploadLink={getUploadLink}
                                    documentGuid={this.props.batchId ? this.props.batchId : document.processInfoGuid}
                                    submitDocuments={this.submitAdditionalEsignDocs}
                                    requestDocuments={requestDocuments}
                                    deleteDocument={this.deleteAdditionalEsignDocument}
                                    deleteBlobAdditionalEsign={deleteBlobAdditionalEsign}
                                    updateDocument={this.updateAdditionalEsignDocument}
                                    isAssignedToLoggedinUser={true}
                                    company={this.props.company}
                                    userSettings={this.props.userSettings}
                                    userProfile={this.props.userBasicProfile}
                                    userSignatures={this.props.userSignatures}
                                    updateEROSignatureStamp={this.updateEroSignatureStamp}
                                    taxYear={document.taxYear}
                                    signatureSettings={document.documentSettings.signatureSettings}
                                    ero={document.ero}
                                    users={this.props.users}
                                    requestDownloadPath={this.props.requestDownloadPath}
                                    readonly={additionalEsignReadOnly}
                                    batchId={this.props.batchId}
                                    deletedEsignDocuments={this.state.deletedEsignDocuments}
                                    convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                                    partners={this.props.partners}
                                />
                            }
                        </Tabs>

                        <Tabs eventKey={OrganizerTabs.AdditionalQuestions}
                            title={<div className="overflowText">Custom Questions</div>}
                            className="zIndex10">
                            {(activeKey === OrganizerTabs.AdditionalQuestions) &&
                                <CustomQuestionsTab templates={this.state.templates}
                                    isEditable={isCustomQuestionEditable}
                                    selectedTemplate={this.state.selectedTemplate}
                                    onSetSelectedTemplate={this.handleTemplateSelection}
                                    onTemplateUpdate={this.handleTemplateUpdate}
                                />
                            }

                        </Tabs>

                        {batchView ? null : <Tabs eventKey={OrganizerTabs.Removed}
                            title={<div className="overflowText">Deleted</div>}
                            className="zIndex10">
                            {(activeKey === OrganizerTabs.Removed) &&
                                <RemovedTab
                                    bookmarks={removedBookmarks}
                                    pdf={document.document}
                                    moveToGroup={(dest: GroupType, page: number) => this.moveToGroup(GroupType.Removed, dest, page)}
                                    pages={removedPages}
                                    engagementLetterExists={engagementExists}
                                    readOnlyView={readOnlyView}
                                    pdfURL={pdfURL}
                                />
                            }

                        </Tabs>}
                        <Tabs eventKey={OrganizerTabs.DeliveryOptions}
                            title={<div className="overflowText">Delivery Options</div>}
                            className="zIndex10">
                            {
                                (activeKey === OrganizerTabs.DeliveryOptions) &&
                                <DeliveryOptionsTab
                                    company={company}
                                    documentSettings={document.documentSettings}
                                    organizerClients={document.organizerClients}
                                    updateClients={this.updateClients}
                                    updateSettings={this.updateDocumentSettings}
                                    preparerMessages={preparerMessages}
                                    formGroups={formGroups}
                                    users={users}
                                    engagementLetterExists={engagementExists}
                                    additionalFileExists={additionalFileExists}
                                    companySettings={companySettings}
                                    readOnlyView={readOnlyView}
                                    generateTaxpayerView={this.generateTaxpayerView}
                                    document={document}
                                    isEsignLetterEnabled={this.isEsignLetterEnabled}
                                />
                            }
                        </Tabs>

                    </Tabs>
                </CustomWindowBody>)
            }

            <CustomWindowFooter>
                <Button
                    variant="default"
                    data-test-auto="27A54F41-0CFF-4F81-B684-2052B7424A9B"
                    className="btn-white"
                    disabled={activeKey === viewMin || loading}
                    onClick={() => this.onPrevious(engagementExists)}>
                    <i className='fa fa-chevron-left'></i>Previous
                </Button>
                <Button
                    variant="default"
                    data-test-auto="B1C60A60-C306-4A4D-B914-AC034DCB71E6"
                    className="btn-white"
                    disabled={activeKey === viewMax || loading}
                    onClick={() => this.onNext(engagementExists)}>
                    <i className='fa fa-chevron-right'></i>Next
                </Button>

                {!batchView ? <Button variant="info" onClick={this.onSave} disabled={loading}><i className='glyphicon glyphicon-floppy-disk'></i>Save & Close</Button> :
                    <Button variant="info" onClick={() => this.props.onClose(this.props.document.id)} > <i className='glyphicon glyphicon-remove'></i>Close</Button>
                }
                {
                    !readOnlyView &&
                    <>
                        <Button
                            disabled={loading}
                            variant="success" onClick={this.onFinishProcessing}
                            ref={(reference: Button | null) => { this.finishPopoverRef = reference }}
                        >
                            <i className='fa fa-check'></i>Finish
                        </Button>

                        <Overlay rootClose={true} placement="top"
                            onHide={() => { this.setState({ showFinishPopover: false }) }}
                            target={this.finishPopoverRef} show={this.state.showFinishPopover}>
                            {finishPopover}
                        </Overlay>
                    </>
                }

                {
                    this.state.showNewClientModal &&
                    <NewClientModal
                        onClose={() => this.setShowNewClientModal(false)}
                        onCancel={this.onCancelClientInfoSave}
                        onAddNewClientInfo={this.onAddNewClientInfo}
                        isMarriedFileJointly={this.state.isMarriedFileJointly}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        partners={this.props.partners.partners}
                    />
                }
                {
                    this.state.showExistingClientDataComparisonModal &&
                    <ExistingClientDataComparisonModal
                        onClose={() => this.setShowExistingClientDataComparisonModal(false)}
                        onCancel={this.onCancelClientInfoUpdate}
                        onUpdate={this.onUpdateExistingClientInfo}
                        isMarriedFileJointly={this.state.isMarriedFileJointly}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        partners={this.props.partners.partners}
                    />
                }
                {
                    this.state.showLocationMismatchActionModel && (
                        <LocationMismatchActionModal
                            onCancel={this.onCloseLocationMismatchActionModal}
                            onContinue={this.onContinueLocationMismatchActionModal}
                            show={this.state.showLocationMismatchActionModel}
                            locationName={this.getLocationName()}
                        />
                    )}
            </CustomWindowFooter>
        </CustomWindow >)
    }

    //-----------Client Management Start----------//
    onClientManagementComparisonFailed = () => {
        this.setState({
            loading: false
        });
        VenusNotifier.Warning(ClientManagementConstants.ClientManagementComparisonFailed, null);
        this.callFinalMethod();
        return;
    }

    onCancelClientInfoSave = () => {
        this.setShowNewClientModal(false);
        this.callFinalMethod();
    }

    onCancelClientInfoUpdate = () => {
        this.setShowExistingClientDataComparisonModal(false);
        this.callFinalMethod();
    }

    callFinalMethod = () => {
        this.setState({ loading: true });

        //If Location is not assigned to user
        if (this.state.isLocationMismatch) {
            this.props.document.createdBy = 0;
            this.props.document.processStatus = ProcessStatus.Ready;
            this.props.updateDocument({ ...this.props.document });
        }

        if (this.state.actionType == ActionType.SaveAndClose) {
            this.saveOrganizerDocument();
        }
        else if (this.state.actionType == ActionType.Finish) {
            this.onShowFinishPopover();
        }
    }

    setShowNewClientModal = (show: boolean) => {
        this.setState({
            showNewClientModal: show
        })
    }

    setShowExistingClientDataComparisonModal = (show: boolean) => {
        this.setState({
            showExistingClientDataComparisonModal: show
        })
    }

    onAddNewClientInfo = () => {
        addNewClientInfo(this.getClientInfoModel(), this.editClientInfoResourceId,
            () => {
                this.setShowNewClientModal(false);
                this.callFinalMethod();
            },
            () => {
                VenusNotifier.Warning(ClientManagementConstants.ClientManagementSaveFailed, null);
                this.callFinalMethod();
            }
        );
    }

    onUpdateExistingClientInfo = () => {
        getClientInfo(this.props.document.clientId, this.editClientInfoResourceId, (clientInfo) => {
            this.setState({
                clientInfoViewModel: clientInfo
            }, () => {
                updateExistingClientInfo(this.getClientInfoModel(), this.editClientInfoResourceId,
                    () => {
                        this.setShowExistingClientDataComparisonModal(false);
                        this.callFinalMethod();
                    },
                    () => {
                        VenusNotifier.Warning(ClientManagementConstants.ClientManagementSaveFailed, null);
                        this.callFinalMethod();
                    }
                );
            });
        });
    }

    private getClientInfoModel = (): IClientInfoViewModel => {
        const { document } = this.props;
        const taxpayer = document.organizerClients.find(client => client.clientType === OrganizerClientType.Taxpayer);
        const spouse = document.organizerClients.find(client => client.clientType === OrganizerClientType.Spouse);
        let clientInfo: IClientInfoViewModel = initialClientInfoViewModel;

        if (this.state.clientInfoViewModel && this.state.clientInfoViewModel.clientId.trim() == document.clientId.trim()) {
            clientInfo.clientBasicInfoId = this.state.clientInfoViewModel.clientBasicInfoId;
        }

        clientInfo.clientId = document.clientId.trim();
        clientInfo.name = taxpayer?.name?.trim() ?? "";
        clientInfo.emailAddress = taxpayer?.email?.trim() ?? "";
        clientInfo.isDeceased = taxpayer?.isDeceased ?? false;
        clientInfo.countryCode = taxpayer?.countryCode ?? "";
        clientInfo.mobileNumber = taxpayer?.mobileNumber?.trim() ?? "";
        clientInfo.spouseName = spouse?.name?.trim() ?? "";
        clientInfo.spouseEmailAddress = spouse?.email?.trim() ?? "";
        clientInfo.spouseMobileNumber = spouse?.mobileNumber?.trim() ?? "";
        clientInfo.spouseCountryCode = spouse?.countryCode ?? "";
        clientInfo.spouseIsDeceased = spouse?.isDeceased ?? false;
        clientInfo.mfj = this.state.isMarriedFileJointly;

        clientInfo.locationId = document.locationId;
        const locationData = document.locationId && this.state.locationsState.find(location => location.value === document.locationId)?.label;
        clientInfo.locationName = locationData ? locationData.toString() : "";
        clientInfo.ero = document.ero.toString();

        const ero = clientInfo.ero && this.props.partners?.partners?.find(partnerUser => partnerUser.id === document.ero);
        clientInfo.eroFirstName = (ero && ero?.firstName) ? ero?.firstName : "";
        clientInfo.eroLastName = (ero && ero?.lastName) ? ero?.lastName : "";

        return clientInfo;
    }

    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NewClient) {
            createNewClientInfoComparisonResult(
                this.getClientInfoModel(),
                (response) => {
                    clientInfoComparisonResult.clientInfoResultData = response;
                });
        }
        this.setState({ clientInfoComparisonResult }, () => {
            if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NoDataMismatch) {
                this.callFinalMethod();
            }
            else if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NewClient) {
                this.setState({ loading: false });
                this.setShowNewClientModal(true);
            }
            else if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.DataMismatch) {
                this.setState({ loading: false });
                this.setShowExistingClientDataComparisonModal(true);
            }
        });
    }
    //-----------Client Management End----------//
}

interface FinishPopoverComponentProps {
    model: OrganizerDocument;
    updateDocument: (document: OrganizerDocument) => void;
    deliver: () => void;
    isTpMfaEnabled: boolean;
    loading: boolean;
}

const FinishPopoverComponent: React.StatelessComponent<FinishPopoverComponentProps> = ({ model, updateDocument, deliver, isTpMfaEnabled, loading }) => {

    const handleDeliverFirstToChange = (type: OrganizerClientType) => {
        model.organizerClients = model.organizerClients.map(client => {
            if (client.clientType === type) {
                client.signingOrder = 1;
                return client;
            }
            return { ...client, signingOrder: 2 };
        })
        updateDocument(model);
    }

    const cancel = () => {
        document.body.click();
    }

    const taxpayer = model.organizerClients.find(client => client.signingOrder === 1);

    return (<Card>
        <Card.Body>
            {
                model.organizerClients.length > 1 ?
                    <div>
                        <p>{DeliveryInfo}</p>
                        <Row>
                            {
                                model.organizerClients
                                    .sort((a: OrganizerClient) => {
                                        if (a.clientType === OrganizerClientType.Taxpayer)
                                            return -1;
                                        else
                                            return 1;
                                    })
                                    .map(client => {
                                        if (client.clientType === OrganizerClientType.Taxpayer) {
                                            return (
                                                <Col sm={4}>
                                                    <RadioButtonComponent
                                                        text="Taxpayer"
                                                        id="taxpayer"
                                                        checked={client.signingOrder === 1 && !client.isDeceased}
                                                        onClick={() => handleDeliverFirstToChange(client.clientType)}
                                                        disabled={client.isDeceased}
                                                    />
                                                </Col>
                                            );
                                        }
                                        if (client.clientType === OrganizerClientType.Spouse) {
                                            return (
                                                <Col sm={4}>

                                                    <RadioButtonComponent
                                                        text="Spouse"
                                                        id="spouse"
                                                        checked={(client.signingOrder === 1 && !client.isDeceased)
                                                            || (model.organizerClients[0].isDeceased && !model.organizerClients[1].isDeceased)}
                                                        onClick={() => handleDeliverFirstToChange(client.clientType)}
                                                        disabled={client.isDeceased}
                                                    />
                                                </Col>)
                                        }
                                    })

                            }

                        </Row>
                    </div> : <div>
                        <Row className="marB15">
                            <Col sm={12}>
                                <RadioButtonComponent text="Taxpayer" id="taxpayer" checked={true} />
                            </Col>
                        </Row>
                    </div>
            }
            <div style={{ display: 'grid', marginTop: '5px' }}>
                <Row className="marB15" style={{ display: 'inline' }}>
                    <Col style={{ float: 'right' }} sm={8} as={Col}>
                        {(taxpayer && !taxpayer.isDeceased) ? taxpayer.name :
                            (model.organizerClients.length > 1 && model.organizerClients.filter(x => !x.isDeceased)[0].name)}
                    </Col>
                    <Col className="text-right" sm={4} as={Col}>
                        Name
                    </Col>

                </Row>
                <Row className="marB15" style={{ display: 'inline' }}>
                    <Col style={{ float: 'right' }} sm={8} as={Col}>
                        {(taxpayer && !taxpayer.isDeceased) ? taxpayer.email :
                            (model.organizerClients.length > 1 && model.organizerClients.filter(x => !x.isDeceased)[0].email)}
                    </Col>
                    <Col className="text-right" sm={4} as={Col}>
                        Email
                    </Col>

                </Row>
                {
                    isTpMfaEnabled &&
                    <div style={{ display: 'inherit' }}>
                        <Row className="marB15" style={{ display: 'inline' }}>
                            <Col style={{ float: 'right' }} sm={8} as={Col}>
                                {(taxpayer && !taxpayer.isDeceased) ? taxpayer.countryCode :
                                    (model.organizerClients.length > 1 && model.organizerClients.filter(x => !x.isDeceased)[0].countryCode)}
                            </Col>
                            <Col className="text-right" sm={4} as={Col}>
                                Country Code
                            </Col>

                        </Row>
                        <Row className="marB15" style={{ display: 'inline' }}>
                            <Col style={{ float: 'right' }} sm={8} as={Col}>
                                {(taxpayer && !taxpayer.isDeceased) ? phoneNumberDisplay(taxpayer.mobileNumber) :
                                    (model.organizerClients.length > 1 && phoneNumberDisplay(model.organizerClients.filter(x => !x.isDeceased)[0].mobileNumber))}
                            </Col>
                            <Col className="text-right" sm={4} as={Col}>
                                Mobile Number
                            </Col>

                        </Row>
                    </div>
                }
            </div>

            <div>
                <Col sm={12}>
                    <Button type="button" variant="info" className="pull-right" data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                        data-resource-id={ResourceIdConstants.DeliverOrganizer}
                        onClick={deliver} disabled={loading}
                    ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                    <Button type="button" className="pull-right marR05 btn-white btn-default" data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                        onClick={cancel}
                    ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                </Col>
            </div>
        </Card.Body>
    </Card>);
}
