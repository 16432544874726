import * as bootbox from 'bootbox';
import * as React from 'react';
import { Guid } from 'guid-typescript';
import { Modal, Button, Col, Row, ProgressBar, FormControl } from 'react-bootstrap';
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { DropzoneComponent } from 'react-dropzone-component';
import {
    FileUploadData, initialUploadData, BatchOrganizerUploadModel, BatchStatus, EngagementListLabelValue, EngagementType
} from './../../../models/OrganizerComponentModels';
import { UploadStatus } from './../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import {
    getFileExtension, validatePdfFileContent,
    validateZipFileType, validateExcelFileType
} from './../../../../components/helper/Validations';
import { OrganizerUploadState, apiPrefix } from './../../../models/OrganizerStoreModels';
import { HideLoader, ShowLoader } from './../../../../components/helper/Loader';
import DropdownComponent from '../../../../components/common/controls/DropdownComponent';
import { getOrganizersTaxYearList, getUserList } from '../../Helper/OrganizerHelperFunctions';
import { IUserModel, IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { UploadtaxReturnConstants } from '../../../../components/helper/Constants';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logger } from '../../../../routes';
import { CheckBoxComponent } from '../../../../components/common/CheckBoxComponent';
import { Link } from 'react-router-dom';
import { CustomEvents, PreparerMessageConstants } from '../../Helper/OrganizerConstants';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { UploadFunctions } from '@sssuite-js-packages/file-utility';
import { getFileSizeFloatingPoint } from '../../../../components/helper/HelperFunctions';

interface BatchUploadModalProps {
    onHide: () => void;
    show: boolean;
    taxSoftware: TaxSoftwareType;
    getUploadLink: (url: string, callback?: (data?: OrganizerUploadState) => void) => void;
    deleteDocument: (url: string, callback: () => void) => void;
    submitDocuments: (url: string, data: any, callback: () => void) => void;
    pageReload: () => void;
    eroUsers: IPartnerModel[];
    userLocations : IDropdown[];
    defaultERO: number;
    downloadCSVTemplate: (callback: () => void) => void;
    enableEngagementLetter: boolean;
}
interface BatchUploadModalState {
    disableSubmit: boolean;
    zipUploadData: FileUploadData;
    excelUploadData: FileUploadData;
    batchName: string;
    batchGuid: string;
    ero: number;
    locationId: number;
    taxYear: number;
    engagementType: EngagementType;
    disableDownload: boolean;
    IsEngagementLetterRequired: boolean;
}

export class BatchUploadModal extends React.Component<BatchUploadModalProps, BatchUploadModalState>{
    private taxYearList: any[];
    private eroList: any[];
    private userLocations : IDropdown[];
    constructor(props: BatchUploadModalProps) {
        super(props);
        this.state = {
            disableSubmit: false,
            zipUploadData: initialUploadData,
            excelUploadData: initialUploadData,
            ero: 0,
            batchName: "",
            batchGuid: "",
            taxYear: 0,
            engagementType: EngagementType.E1040,
            disableDownload: false,
            IsEngagementLetterRequired: false,
            locationId : 0,
        };
        this.taxYearList = getOrganizersTaxYearList();
        this.eroList = [];
        this.userLocations = this.props.userLocations || [];
    }

    componentDidMount() {
        this.eroList = getUserList(this.props.eroUsers);
        this.setState({
            batchGuid: Guid.create().toString(),
            ero: this.props.defaultERO,
            IsEngagementLetterRequired: this.props.enableEngagementLetter
        });
        if(this.props.userLocations && this.props.userLocations.length === 1){
            this.setState({locationId: this.props.userLocations[0].value})
        }
    }

    componentWillReceiveProps(nextProps: BatchUploadModalProps) {
        if (nextProps && nextProps.eroUsers.length !== this.props.eroUsers.length) {
            this.eroList = getUserList(nextProps.eroUsers);
        }

        if (nextProps.defaultERO !== this.props.defaultERO) {
            this.setState({
                ero: nextProps.defaultERO
            })
        }
    }

    validateFileSize = (file: any): boolean => {
        if (file.size > 1181116006.4) {
            VenusNotifier.Error(`${file.name} cannot be uploaded since the size is greater than 1 GB.`, null);
            return false;
        }
        if (file.size <= 0) {
            VenusNotifier.Error(`${file.name} cannot be uploaded since it is empty`, null);
            return false;
        }
        return true;
    }

    reset = () => {
        this.setState({
            disableSubmit: true,
            zipUploadData: initialUploadData,
            excelUploadData: initialUploadData,
            ero: 0,
            batchName: "",
            taxYear: 0,
            engagementType: EngagementType.E1040,
            locationId : 0,
        });
    }

    validateZipFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length > 1 || this.state.zipUploadData.name) {
            VenusNotifier.Warning(`You cannot upload more than one zip file.`, null);
            throw new Error();
        }
    }

    validateExcelFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length > 1 || this.state.excelUploadData.name) {
            VenusNotifier.Warning(`You cannot upload more than one csv file.`, null);
            throw new Error();
        }
    }


    convertToZipModel = (uploadedFiles: any): FileUploadData => {
        let uploadData: FileUploadData = {} as FileUploadData;
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (this.validateFileSize(uploadedFile)) {
                const tmpFileName = uploadedFiles[i].name;
                const fileExtension = getFileExtension(tmpFileName);
                if (validateZipFileType(fileExtension)) {
                    uploadData = {
                        uuid: "",
                        name: tmpFileName,
                        progressBar: 0,
                        size: getFileSizeFloatingPoint(uploadedFile.size),
                        status: UploadStatus.Wait,
                        sasGuid: this.state.batchGuid,
                        file: uploadedFile,
                        disable: true
                    };
                }
                else {
                    throw Error();
                }
            }
        }
        return uploadData;
    }

    convertToExcelModel = (uploadedFiles: any): FileUploadData => {
        let uploadData: FileUploadData = {} as FileUploadData;
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (this.validateFileSize(uploadedFile)) {
                const tmpFileName = uploadedFiles[i].name;
                const fileExtension = getFileExtension(tmpFileName);
                if (validateExcelFileType(fileExtension)) {
                    uploadData = {
                        uuid: "",
                        name: tmpFileName,
                        progressBar: 0,
                        size: getFileSizeFloatingPoint(uploadedFile.size),
                        status: UploadStatus.Wait,
                        sasGuid: this.state.batchGuid,
                        file: uploadedFile,
                        disable: true
                    };
                }
                else {
                    throw Error();
                }
            }
        }
        return uploadData;
    }


    getZipUploadLink = () => {

        const _self = this;
        const uploadHelperFunctions = new UploadFunctions();
        const { zipUploadData } = this.state;
        zipUploadData.status = UploadStatus.Initiating;

        this.props.getUploadLink(`${apiPrefix}Document/GetZipUploadLink/${zipUploadData.sasGuid}`, (data?: OrganizerUploadState) => {

            try {

                if (data) {

                    zipUploadData.status = UploadStatus.Uploading;

                    _self.setState({ zipUploadData: zipUploadData },
                        () => uploadHelperFunctions.uploadFile(zipUploadData.file, data, zipUploadData.name ? zipUploadData.name : "",
                            _self.uploadZipProgressCallback, _self.uploadZipCommitCallBack));
                } else {
                    throw new Error("Upload link not found !!");
                }

            } catch (error) {
                _self.setState({
                    zipUploadData: initialUploadData
                });
            }

        });
    }

    uploadZipProgressCallback = (percent: number, fileToUpload: any) => {
        const { zipUploadData } = this.state;

        if (zipUploadData.sasGuid == fileToUpload.fileGUID) {
            zipUploadData.progressBar = percent - 10;
        }

        this.setState({
            zipUploadData: zipUploadData
        });
    }

    uploadZipCommitCallBack = (fileToUpload: any) => {

        const { zipUploadData } = this.state;

        if (zipUploadData.sasGuid == fileToUpload.fileGUID) {
            zipUploadData.progressBar = 100;
            zipUploadData.status = UploadStatus.Uploaded;
            zipUploadData.disable = false;
        }
        this.setState({
            zipUploadData: zipUploadData
        }, () => {
            if (!this.isZipUploadOnGoing() && !this.isExcelUploadOnGoing()) {
                this.setState({ disableSubmit: false });
            }
        });
    }

    isZipUploadOnGoing = (): boolean => {
        let isUploading = false;
        const { zipUploadData } = this.state;
        if (zipUploadData.progressBar == undefined ? 0 : zipUploadData.progressBar < 100) {
            isUploading = true;
        }
        return isUploading;
    }

    deleteZip = () => {

        const _self = this;
        bootbox.confirm({
            message: "Are you sure you want to delete this file?",
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let { zipUploadData } = _self.state;
                    _self.props.deleteDocument(`${apiPrefix}Document/DeleteZip/${zipUploadData.sasGuid}`, () => {
                        zipUploadData = initialUploadData;
                        _self.setState({ disableSubmit: true, zipUploadData: zipUploadData });
                    });
                }
            }
        });

    }

    getExcelUploadLink = () => {

        const _self = this;
        const uploadHelperFunctions = new UploadFunctions();
        const { excelUploadData } = this.state;
        excelUploadData.status = UploadStatus.Initiating;

        this.props.getUploadLink(`${apiPrefix}Document/GetExcelUploadLink/${excelUploadData.sasGuid}`, (data?: OrganizerUploadState) => {

            try {

                if (data) {

                    excelUploadData.status = UploadStatus.Uploading;

                    _self.setState({ excelUploadData: excelUploadData },
                        () => uploadHelperFunctions.uploadFile(excelUploadData.file, data, excelUploadData.name ? excelUploadData.name : "",
                            _self.uploadExcelProgressCallback, _self.uploadExcelCommitCallBack));
                } else {
                    throw new Error("Upload link not found !!");
                }

            } catch (error) {
                _self.setState({
                    excelUploadData: initialUploadData
                });
            }

        });
    }

    uploadExcelProgressCallback = (percent: number, fileToUpload: any) => {
        const { excelUploadData } = this.state;

        if (excelUploadData.sasGuid == fileToUpload.fileGUID) {
            excelUploadData.progressBar = percent - 10;
        }

        this.setState({
            excelUploadData: excelUploadData
        });
    }

    uploadExcelCommitCallBack = (fileToUpload: any) => {

        const { excelUploadData } = this.state;

        if (excelUploadData.sasGuid == fileToUpload.fileGUID) {
            excelUploadData.progressBar = 100;
            excelUploadData.status = UploadStatus.Uploaded;
            excelUploadData.disable = false;
        }
        this.setState({
            excelUploadData: excelUploadData
        }, () => {
            if (!this.isExcelUploadOnGoing() && !this.isZipUploadOnGoing()) {
                this.setState({ disableSubmit: false });
            }
        });
    }

    isExcelUploadOnGoing = (): boolean => {
        let isUploading = false;
        const { excelUploadData } = this.state;
        if (excelUploadData.progressBar == undefined ? 0 : excelUploadData.progressBar < 100) {
            isUploading = true;
        }
        return isUploading;
    }

    deleteExcel = () => {

        const _self = this;
        bootbox.confirm({
            message: "Are you sure you want to delete this file?",
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let { excelUploadData } = _self.state;
                    _self.props.deleteDocument(`${apiPrefix}Document/DeleteCsv/${excelUploadData.sasGuid}`, () => {
                        excelUploadData = initialUploadData;
                        _self.setState({ disableSubmit: true, excelUploadData: excelUploadData });
                    });
                }
            }
        });

    }

    onSubmit = () => {
        if (this.validateUploadData()) {
            const TraceEvent: IEventTelemetry = {
                name: `${CustomEvents.BatchUpload}${this.props.taxSoftware == TaxSoftwareType.ProSystems ? "CCH" : TaxSoftwareType[this.props.taxSoftware].toString()}`,
                properties: { count: 1 }
            };
            logger?.trackEvent(TraceEvent);
            ShowLoader();
            this.props.submitDocuments(`${apiPrefix}Document/SaveBatch`,
                JSON.stringify(this.getUploadData()), () => {
                    this.uploadConfirmation();
                    this.props.pageReload();
                    this.props.onHide();
                    HideLoader();
                });
        }
    }

    validateUploadData = (): boolean => {
        let valid = true;
        const { batchName, excelUploadData, zipUploadData, ero, engagementType, taxYear, locationId } = this.state;
        if (!batchName || batchName.trim() === "") {
            VenusNotifier.Warning("Please enter Batch Name to submit", "");
            valid = false;
        }

        if (!ero) {
            VenusNotifier.Warning("Please select ERO / Signer to submit", "");
            valid = false;
        }

        if (!locationId) {
            VenusNotifier.Warning("Please select office location", "");
            valid = false;
        }

        if (!engagementType) {
            VenusNotifier.Warning("Please select engagement type to submit", "");
            valid = false;
        }

        if (!taxYear) {
            VenusNotifier.Warning("Please select tax year to submit", "");
            valid = false;
        }

        if (!excelUploadData.name) {
            VenusNotifier.Warning("Please upload Client Details Excel File to submit", "");
            valid = false;
        }

        if (!zipUploadData.name) {
            VenusNotifier.Warning("Please upload Zip File to submit", "");
            valid = false;
        }
        return valid;
    }

    uploadConfirmation = () => {
        let message = `<h5 class='marT0'>The following Batch was successfully submitted :</h5><ul class='marL30'>`;
        message = message + `<li>${this.state.batchName}</li>`;
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            }
        });
    }

    handleClose = () => {
        this.reset();
        this.props.onHide();
    }

    handleUploaderClose = () => {
        if (!this.state.zipUploadData.name && !this.state.excelUploadData.name) {
            this.handleClose();
            return;
        }
        if ((this.state.excelUploadData.name && this.isExcelUploadOnGoing()) ||
            (this.state.zipUploadData.name && this.isZipUploadOnGoing())) {
            bootbox.alert({
                message: UploadtaxReturnConstants.AbortingUploadWarning,
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                }
            });
        }
        else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.handleClose();
                        this.forceUpdate();
                    }
                }
            });
        }
    }

    getUploadData = (): BatchOrganizerUploadModel => {
        const { batchName, batchGuid, ero, taxYear, engagementType, locationId } = this.state;

        return {
            batchName: batchName,
            id: batchGuid,
            ero: ero,
            locationId : locationId,
            status: BatchStatus.Uploaded,
            uploadedBy: 0,
            uploadedOn: new Date(),
            deliveredBy: 0,
            deliveredOn: undefined,
            engagementType: engagementType,
            taxYear: taxYear,
            taxSoftware: this.props.taxSoftware,
            enableEngagementLetter: this.props.taxSoftware == TaxSoftwareType.ProSystems || this.state.IsEngagementLetterRequired,
            batchSize: this.getBatchSize()
        };
    }

    onEngagementTypeChange = (eventValue: any) => {
        this.setState({
            engagementType: eventValue
        });
    }

    onTaxYearChange = (eventValue: any) => {
        this.setState({
            taxYear: eventValue
        });
    }

    onEROChange = (eventValue: any) => {
        this.setState({
            ero: eventValue
        });
    }

    onLocationChange = (eventValue: any) => {
        this.setState({
            locationId: eventValue
        });
    }


    toggleDownloadDisable = () => {
        this.setState({
            disableDownload: !this.state.disableDownload
        })
    }
    engamentLetterRequiredChange = (event: any) => {
        const _self = this;

        this.setState({
            IsEngagementLetterRequired: event.currentTarget.checked,

        });
    }


    getBatchSize() {
        var excelUploadFileSizeInKB = 0;
        var zipUploadFileSizeInKB = 0;

        const { excelUploadData } = this.state;
        const { zipUploadData } = this.state;

        var splittedExcelUploadFileSize = new String(excelUploadData.size).split(" ");
        if (splittedExcelUploadFileSize[1] == "GB") {
            excelUploadFileSizeInKB = Math.round(Number(splittedExcelUploadFileSize[0])) * 1024 * 1024;
        }
        else if (splittedExcelUploadFileSize[1] == "MB") {
            excelUploadFileSizeInKB = Math.round(Number(splittedExcelUploadFileSize[0])) * 1024;
        }
        else {
            excelUploadFileSizeInKB = Math.round(Number(splittedExcelUploadFileSize[0]));
        }

        var splittedZipUploadFileSize = new String(zipUploadData.size).split(" ");
        if (splittedZipUploadFileSize[1] == "GB") {
            zipUploadFileSizeInKB = Math.round(Number(splittedZipUploadFileSize[0])) * 1024 * 1024;
        }
        else if (splittedZipUploadFileSize[1] == "MB") {
            zipUploadFileSizeInKB = Math.round(Number(splittedZipUploadFileSize[0])) * 1024;
        }
        else {
            zipUploadFileSizeInKB = Math.round(Number(splittedZipUploadFileSize[0]));
        }

        var totalFilesSize = excelUploadFileSizeInKB + zipUploadFileSizeInKB;
        return totalFilesSize + " " + "KB";
    }

    render() {
        const zipFileConfig = {
            parallelUploads: 10000,
            uploadMultiple: false,
            autoProcessQueue: false,
            addRemoveLinks: true,
            acceptedFiles: "application/zip",
            headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
            previewsContainer: false,
        };

        const zipComponentConfig = {
            dropzoneSelector: 'div.filepicker',
            iconFiletypes: ['.pdf'],
            showFiletypeIcon: true,
            postUrl: "/api/",
            uploadMultiple: false,
        }

        const zipFileUploadHandler = {
            init: () => {
                this.reset();
            },
            addedfiles: (files: any) => {
                try {
                    const _self = this;
                    _self.validateZipFileCount(files);
                    const uploadData: FileUploadData = _self.convertToZipModel(files);


                    if (uploadData && uploadData.sasGuid) {
                        _self.setState({
                            zipUploadData: uploadData,
                            disableSubmit: true
                        }, () => {
                            _self.getZipUploadLink();
                        });
                    }

                }
                catch (error) {
                    //VenusNotifier.Warning(error.message, null);
                }
            }
        }

        const excelFileConfig = {
            parallelUploads: 10000,
            uploadMultiple: false,
            autoProcessQueue: false,
            addRemoveLinks: true,
            acceptedFiles: ".csv, text/csv",
            headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
            previewsContainer: false
        };

        const excelComponentConfig = {
            //dropzoneSelector: 'div.filepicker1',
            iconFiletypes: ['.csv'],
            showFiletypeIcon: true,
            postUrl: "/api/",
            uploadMultiple: false,
        }

        const excelFileUploadHandler = {
            init: () => {
                this.reset();
            },
            addedfiles: (files: any) => {
                try {
                    const _self = this;
                    _self.validateExcelFileCount(files);
                    const uploadData: FileUploadData = _self.convertToExcelModel(files);

                    _self.setState({
                        excelUploadData: uploadData,
                        disableSubmit: true
                    }, () => {
                        _self.getExcelUploadLink();
                    });

                }
                catch (error) {
                    //VenusNotifier.Warning(error.message, null);
                }
            }
        }


        const { zipUploadData, excelUploadData, ero, engagementType, taxYear, disableSubmit, batchName, disableDownload, locationId } = this.state;
        const { show, taxSoftware, downloadCSVTemplate } = this.props;
        return (
            <Modal className="upload-doc-modal"
                show={show}
                onHide={this.handleUploaderClose} enforceFocus={false}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        {` Upload Batch ${taxSoftware == TaxSoftwareType.ProSystems ? "CCH" : TaxSoftwareType[taxSoftware].toString()} Document `}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-doc-body">
                    <div className='batch-note-info'>
                        <i className="fas fa-info-circle"></i>
                        {PreparerMessageConstants.UploadFileWarning}
                    </div>

                    <div className="pad1 upload-document-row">
                            <div className="batch-upload-options-item upload-document-text">Batch Name</div>
                            <div className="width15 batch-upload-options-item">
                                <FormControl type="text"
                                    style={{ fontSize: '14px' }}
                                    value={batchName}
                                    onChange={(e: any) => this.setState({ batchName: e.target.value })} 
                                />
                            </div>


                            <div className="batch-upload-options-item upload-document-text">ERO</div>
                            <div className="width15 batch-upload-options-item">
                                <DropdownComponent
                                    options={this.eroList}
                                    id={"ero"}
                                    selectedValue={ero}
                                    clearable={false}
                                    onChange={this.onEROChange}
                                />
                            </div>


                        <div className="batch-upload-options-item upload-document-text">Office Location</div>
                            <div className="width15 batch-upload-options-item">
                                <DropdownComponent
                                    options={this.userLocations}
                                    id={"locationId"}
                                    selectedValue={locationId}
                                    clearable={false}
                                    onChange={this.onLocationChange}
                                    disabled={this.userLocations.length === 1}
                                />
                            </div>

                            <div className="batch-upload-options-item upload-document-text">Tax Year</div>
                            <div className="width15 batch-upload-options-item">
                                <DropdownComponent
                                    options={this.taxYearList}
                                    id={"taxYear"}
                                    selectedValue={taxYear}
                                    clearable={false}
                                    onChange={this.onTaxYearChange}
                                />
                            </div>

                            <div className="batch-upload-options-item upload-document-text">Tax Return Type</div>
                            <div className="width15 batch-upload-options-item"  title="Supporting 1040 Form Only">
                                <DropdownComponent
                                    options={EngagementListLabelValue}
                                    disabled={true}
                                    id={"engagementType"}
                                    selectedValue={engagementType}
                                    onChange={this.onEngagementTypeChange}
                                />
                            </div>
                </div>

                    {taxSoftware != TaxSoftwareType.ProSystems &&
                        <Row style={{ marginBottom: '15px' }}>
                            <Col xs={3} className="batch-upload-options">
                                <CheckBoxComponent
                                    id={"IsEngagementLetterRequired"}
                                    text={"No Engagement Letter"}
                                    checked={this.state.IsEngagementLetterRequired}
                                    onChange={this.engamentLetterRequiredChange}
                                    datatestAuto={"D2745378-A4BB-45C3-A695-E22CA0F59F67"}
                                    className={"batch-upload-options-checkBox"}
                                />
                                <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="If checked, any Engagement Letter uploaded will not be processed.">
                                    <i className="glyphicon glyphicon-question-sign"></i>
                                </Link>
                            </Col>
                        </Row>
                    }
                    <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                        <Col sm={6} style={{ paddingRight: '0px' }}>
                            <Row className={"upload-container"}>
                                <Row style={{ borderBottom: '1px solid gray', width: '100%', margin: 'auto' }}>
                                    <div className={"upload-header"}>Select Zip File</div>
                                </Row>
                                <Row style={{ width: '100%', margin: 'auto' }}>
                                    <DropzoneComponent
                                        config={zipComponentConfig}
                                        eventHandlers={zipFileUploadHandler}
                                        djsConfig={zipFileConfig}

                                    />
                                </Row>
                                {
                                    zipUploadData.name &&

                                    <Row className={"upload-footer"}>
                                        <Col xs={4} title={zipUploadData.name} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: 'nowrap' }}>
                                            {zipUploadData.name}
                                        </Col>
                                        <Col xs={4} style={{ margin: 'auto' }}>
                                            <ProgressBar variant={(zipUploadData.progressBar != undefined) && (zipUploadData.progressBar != 100) ? "warning" : "success"}
                                                now={zipUploadData.progressBar} />
                                        </Col>
                                        <Col title={zipUploadData.size} xs={2} style={{ margin: 'auto' }}>
                                            {zipUploadData.size}
                                        </Col>
                                        <Col xs={2} style={{ margin: 'auto' }}>
                                            <button type="button" className="btn-white btn-xs"
                                                disabled={zipUploadData.disable}
                                                onClick={() => this.deleteZip()}
                                                style={{ border: 'none' }}>
                                                <i className="text-danger glyphicon glyphicon-remove"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Col>
                        <Col sm={6} style={{ paddingLeft: '0px' }}>
                            <Row className={"upload-container"} style={{ borderLeft: 'none' }}>
                                <Row style={{ borderBottom: '1px solid gray', width: '100%', margin: 'auto' }}>
                                    <div className={"upload-header"}>Select CSV File</div>
                                </Row>
                                <Row style={{ width: '100%', margin: 'auto' }}>
                                    <DropzoneComponent
                                        config={excelComponentConfig}
                                        eventHandlers={excelFileUploadHandler}
                                        djsConfig={excelFileConfig}

                                    />
                                </Row>
                                {
                                    excelUploadData.name &&

                                    <Row className={"upload-footer"}>
                                        <Col xs={4} title={excelUploadData.name} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: 'nowrap' }}>
                                            {excelUploadData.name}
                                        </Col>
                                        <Col xs={4} style={{ margin: 'auto' }}>
                                            <ProgressBar variant={(excelUploadData.progressBar != undefined) && (excelUploadData.progressBar != 100) ? "warning" : "success"}
                                                now={excelUploadData.progressBar} />
                                        </Col>
                                        <Col title={excelUploadData.size} xs={2} style={{ margin: 'auto' }}>
                                            {excelUploadData.size}
                                        </Col>
                                        <Col xs={2} style={{ margin: 'auto' }}>
                                            <button type="button" className="btn-white btn-xs"
                                                disabled={excelUploadData.disable}
                                                onClick={() => this.deleteExcel()}
                                                style={{ border: 'none' }}>
                                                <i className="text-danger glyphicon glyphicon-remove"></i>
                                            </button>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={{ size: 6 }} style={{ fontSize: '14px' }}>
                            <span className="glyphicon glyphicon-asterisk compulsory-star-only padR05"
                                style={{
                                    display: 'inline - block',
                                    position: 'absolute',
                                    top: '5px'
                                }}></span>
                            <span style={{
                                display: 'inline - block',
                                position: 'absolute',
                                left: '25px'
                            }}>File size limit is 1GB.</span>
                        </Col>

                        <Col sm={{ size: 6 }} style={{ fontSize: '14px' }}>
                            <a onClick={() => {
                                this.toggleDownloadDisable();
                                downloadCSVTemplate(this.toggleDownloadDisable)
                            }} className={disableDownload ? 'list-disabled' : ''}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="fas fa-file-download" style={{ paddingRight: '5px' }}></i>
                                Download CSV Template
                            </a>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.handleUploaderClose}>
                        <i className='glyphicon glyphicon-remove'></i>Close</Button>

                    <Button variant="info" onClick={this.onSubmit}
                        disabled={disableSubmit}>
                        <i className='glyphicon glyphicon-floppy-disk'></i>Submit</Button>

                </Modal.Footer>
            </Modal>
        );
    }
}
