import * as React from 'react';
import * as bootbox from 'bootbox';
import { EmailMessageComponent } from './EmailMessage/EmailMessage';
import { EmailMessageModal } from './EmailMessage/EmailMessageModal';
import { VenusNotifier } from './../../../components/helper/VenusNotifier';
import { decodeText } from './../Helper/OrganizerHelperFunctions';
import { EmailMessageConstants, PreparerMessageConstants } from './../Helper/OrganizerConstants';
import { RouteComponentProps } from 'react-router';
import * as PreparerMessageStore from './../../store/OrganizerPreparerMessageStore';
import * as EmailMessageStore from './../../store/OrganizerEmailMessageStore';
import * as CompanySetings from './../../store/OrganizerCompanySettingsStore';
import {
    OrganizerEmailMessageStoreState,
    OrganizerPrepareMessageStoreState, OrganizerSettingsStoreState,

} from './../../models/OrganizerStoreModels';
import {
    AuthenticationOption, EmailMessage, EmailMessageType, PreparerMessage,
    initialPreparerMessage, initialEmailMessage, ReminderTypesNames,
    GlobalOperationAutoReminderRequest
} from './../../models/OrganizerComponentModels';
import { getVariableList } from './../Helper/OrganizerHelperFunctions';
import { ReminderTemplateComponent } from './Reminder/ReminderTemplateComponent';
import * as CompanyStore from './../../../store/company/CompanyStore';
import { Options } from 'react-select';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
const htmlencode = require('htmlencode');

type OrganizerSettingsProps = {
    emailMessageStore: OrganizerEmailMessageStoreState;
    companySettingStore: OrganizerSettingsStoreState;
    preparerMessageStore: OrganizerPrepareMessageStoreState;
    companyData: CompanyStore.ICompanyData;
}
    & typeof EmailMessageStore.actionCreators
    & typeof CompanySetings.ActionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof CompanyStore.actionCreators
    & RouteComponentProps<{}>

interface OrganizerSettingsState {
    showLoader: boolean;
    loaderText: string;
    showPopUpLoader: boolean;
    defaultMessageId: number;
    selectedMessageId: number;
    showEmailMessagePopup: boolean;
    buttonText: string;
    isUpdateMessage: boolean;
    title: string;
    typeId: number;
    selectedOrganizerInitialMessage: number;
    selectedOrganizerReminderMessage: number;
    selectedPrepareMessageId: number;
    selectedDownloadELMessage: number;
    defaultPrepareMessageId: number;
    showAddMessagePopUp: boolean;
    isUpdatePrepareMessage: boolean;
    prepareMessageButtonText: string;
    prepareMessageTitle: string;
    selectedReminderType: EmailMessageType;
    sourceDocumentEnabled: boolean;
}

export class OrganizerSettings extends React.Component<OrganizerSettingsProps, OrganizerSettingsState>{
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false,
            loaderText: "Loading...",
            showPopUpLoader: false,
            defaultMessageId: 0,
            selectedMessageId: 0,
            showEmailMessagePopup: false,
            buttonText: "",
            isUpdateMessage: false,
            title: "",
            typeId: 0,
            selectedOrganizerInitialMessage: 0,
            selectedOrganizerReminderMessage: 0,
            selectedPrepareMessageId: 0,
            defaultPrepareMessageId: 0,
            showAddMessagePopUp: false,
            isUpdatePrepareMessage: false,
            prepareMessageButtonText: "",
            prepareMessageTitle: "",
            selectedDownloadELMessage: 0,
            selectedReminderType: EmailMessageType.None,
            sourceDocumentEnabled: false
        }
    }

    componentDidMount() {
        this.props.requestOrganizerCompanySettings();
        this.props.requestEmailMessages();
        this.props.requestPreparerMessages();
        this.props.requestCompanySettings();
        this.props.requestCommonSettings();
    }

    componentWillReceiveProps(nextProps: OrganizerSettingsProps) {
        if (nextProps.companySettingStore.companySettings) {
            this.setState({
                defaultPrepareMessageId: nextProps.companySettingStore.companySettings.defaultSettings.savedMessage,
            })
        }
        this.setState({
            showLoader: !nextProps.emailMessageStore.loading &&
                !nextProps.companySettingStore.loading &&
                !nextProps.preparerMessageStore.loading ? false : true,
            showPopUpLoader: !nextProps.emailMessageStore.loading &&
                !nextProps.companySettingStore.loading &&
                !nextProps.preparerMessageStore.loading ? false : true,
        }, () => {
            if (!this.state.showPopUpLoader) this.setState({
                showEmailMessagePopup: false,
                showAddMessagePopUp: false
            })
        })
        if (nextProps.companyData) {
            this.setState({
                sourceDocumentEnabled: nextProps.companySettingStore.companySettings.sourceDocumentSettings.enabledSourceDocuments == true
            })
        }
    }


    onEmailInstructionAddButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Client Instruction",
            typeId: typeId
        })
    }

    onEmailInstructionEditButtonClick = (defaultMessageId: number, selectedMessageId: number, typeId: number) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Client Instruction",
            typeId: typeId
        })
    }

    deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number) => {
        const selectedMessage = this.props.emailMessageStore.emailMessages.find(message => message.id == selectedMessageId);
        if (!selectedMessage || selectedMessage.id == defaultMessageId) {
            VenusNotifier.Warning(EmailMessageConstants.DeleteInstructionWarning, "")
        }
        else {
            const _currentObject = this
            bootbox.confirm({
                title: 'Delete Instruction',
                message: 'Are you sure, You want to delete this Instruction ?',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessage
                            (selectedMessage);
                        _currentObject.setState({ showLoader: true })
                    }
                }
            });
        }
    }

    onOrganizerInitialListItemClick = (messageId: number) => {
        this.setState({ selectedOrganizerInitialMessage: messageId })
    }

    onOrganizerReminderListItemClick = (messageId: number) => {
        this.setState({ selectedOrganizerReminderMessage: messageId })
    }
    onOrganizerDownloadEngagementLetterListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadELMessage: messageId })
    }

    onCancelButtonClick = () => {
        this.setState({
            showEmailMessagePopup: false
        })
    }

    saveEmailMessage = (message: EmailMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.type = this.state.typeId;
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.saveEmailMessage(message, this.props.companySettingStore.companySettings, isSetDefault);
        this.setState({
            showPopUpLoader: true,
            selectedOrganizerReminderMessage:0
        })
    }

    editEmailMessage = (message: EmailMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.updateEmailMessage(message);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id)
        } else {
            if (message.type == this.state.selectedReminderType
                && message.id == this.state.defaultMessageId) {
                    this.updateGlobalAutomaticReminder();
            }
        }
        this.setState({
            showPopUpLoader: true
        })
    }

    saveDefaultMessage = (messageId: number) => {
        const { companySettings } = this.props.companySettingStore;
        let isRescheduleReminders = false;
        switch (this.state.typeId) {
            case EmailMessageType.InitialEmail:
                companySettings.defaultSettings.initialEmail = messageId;
                break;
            case EmailMessageType.EngagementReminderEmail:
                companySettings.defaultSettings.engLetterReminderEmail = messageId;
                isRescheduleReminders = true;
                break;
            case EmailMessageType.DownloadEngagementLetterEmail:
                companySettings.defaultSettings.downloadEngagementLetterEmail = messageId;
                break;
            case EmailMessageType.OrganizerReminderEmail:
                companySettings.defaultSettings.organizerReminderEmail = messageId;
                isRescheduleReminders = true;
                break;
            case EmailMessageType.SourceDocReminderEmail:
                companySettings.defaultSettings.sourceDocReminderEmail = messageId;
                break;
        }

        this.props.updateOrganizerCompanySettings(companySettings);
        if (isRescheduleReminders) {
            this.updateGlobalAutomaticReminder();
        }
        
    }

    updateGlobalAutomaticReminder = () => {
        if (this.state.typeId == EmailMessageType.EngagementReminderEmail
            || this.state.typeId == EmailMessageType.OrganizerReminderEmail) {
                let globalAutoReminderRequest: GlobalOperationAutoReminderRequest = {
                    emailMessageType: this.state.typeId
                };
                this.props.updateGlobalAutomaticReminder(globalAutoReminderRequest);
        }
    }

    onChangeAuthenticationOption = (e: any) => {
        const { companySettings } = this.props.companySettingStore;
        companySettings.authenticationSettings.authenticationOption = e.target.value == AuthenticationOption.Text ?
            AuthenticationOption.Text : AuthenticationOption.Email;
        this.props.updateOrganizerCompanySettings(companySettings);
    }
    

    openAddMessagePopUp = () => {
        this.setState({
            showAddMessagePopUp: true,
            prepareMessageButtonText: "Add",
            isUpdatePrepareMessage: false,
            prepareMessageTitle: "Add Saved Message",
            loaderText: "Saving message"
        });
    }

    onListItemClick = (messageId: number) => {
        this.setState({
            selectedPrepareMessageId: messageId
        });
    }


    addButtonClick = (messageDetail: PreparerMessage, isSetDefault: boolean, isToggled: boolean) => {
        const { companySettings } = this.props.companySettingStore;
        messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
        this.props.savePreparerMessage(messageDetail, companySettings, isSetDefault);
        this.setState({ selectedPrepareMessageId: this.state.defaultPrepareMessageId, showPopUpLoader: true })
    }

    updateButtonClick = (messageDetail: PreparerMessage, isSetDefault: boolean, isToggled: boolean) => {
        const { companySettings } = this.props.companySettingStore;
        if (isToggled) {
            if (isSetDefault) {
                companySettings.defaultSettings.savedMessage = messageDetail.id;
                this.props.updateOrganizerCompanySettings(companySettings);
            } else {
                VenusNotifier.Warning(PreparerMessageConstants.SelectDefaultMessageWarning, null);
                return false;
            }
        }
        messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
        this.props.updatePreparerMessage(messageDetail);
        this.setState({
            isUpdatePrepareMessage: false,
            showPopUpLoader: true
        })
    }

    deleteButtonClick = () => {
        if (this.state.selectedPrepareMessageId == 0 || this.state.selectedPrepareMessageId == this.state.defaultPrepareMessageId) {
            bootbox.alert({
                message: PreparerMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            const _currentObject = this
            bootbox.confirm({
                title: 'Delete Message',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        const selectedMessage = _currentObject.props.preparerMessageStore.preparerMessages
                            .find(message => message.id == _currentObject.state.selectedPrepareMessageId);
                        if (selectedMessage) {
                            _currentObject.props.deletePreparerMessage(selectedMessage);
                            _currentObject.setState({
                                selectedPrepareMessageId: _currentObject.state.defaultPrepareMessageId,
                                showLoader: true,
                                loaderText: "Deleting message"
                            })
                        }
                    }
                }
            });
        }
    }

    closeAddPopUp = () => {
        this.setState({ showAddMessagePopUp: false, isUpdatePrepareMessage: false });
    }

    openEditMessagePopUp = () => {
        this.setState({
            showAddMessagePopUp: true,
            prepareMessageButtonText: "Save",
            isUpdatePrepareMessage: true,
            prepareMessageTitle: "Edit Saved Message",
            loaderText: "Saving message"
        });
    }

    onChangeReminderDropdown = (value: any) => {
        let reminderValue = (value == null ? EmailMessageType.None : value);
        this.setState({
            selectedReminderType: reminderValue,
            typeId: reminderValue,
            selectedOrganizerReminderMessage: this.getDefaultReminder(value)
        });
    }

    getDefaultReminder = (type: any) => {
        const { companySettings: { defaultSettings: { engLetterReminderEmail, organizerReminderEmail, sourceDocReminderEmail } } } = this.props.companySettingStore;
        let reminderEmailId: number;
        switch (type) {
            case EmailMessageType.EngagementReminderEmail:
                reminderEmailId = engLetterReminderEmail;
                break;
            case EmailMessageType.OrganizerReminderEmail:
                reminderEmailId = organizerReminderEmail;
                break;
            case EmailMessageType.SourceDocReminderEmail:
                reminderEmailId = sourceDocReminderEmail;
                break;
            default:
                reminderEmailId = engLetterReminderEmail;
                break;
        }
        return reminderEmailId;
    }

    getReminderDropDown = () => {
        let options:Options = [];
        options.push({ "value": EmailMessageType.EngagementReminderEmail, "label": ReminderTypesNames[EmailMessageType.EngagementReminderEmail] });
        options.push({ "value": EmailMessageType.OrganizerReminderEmail, "label": ReminderTypesNames[EmailMessageType.OrganizerReminderEmail] });
        if (this.state.sourceDocumentEnabled)
            options.push({ "value": EmailMessageType.SourceDocReminderEmail, "label": ReminderTypesNames[EmailMessageType.SourceDocReminderEmail] });
        return options;
    }

    getSelectedMessageOnRender = (type: EmailMessageType, initialEmail: number, engLetterReminderEmail: number, downloadEngagementLetterEmail: number) => {
        switch (type) {
            case EmailMessageType.InitialEmail:
                return initialEmail;
            case EmailMessageType.EngagementReminderEmail:
                return engLetterReminderEmail;
            case EmailMessageType.DownloadEngagementLetterEmail:
                return downloadEngagementLetterEmail;
        }
    }

    render() {
        const { showLoader, loaderText, defaultPrepareMessageId, selectedPrepareMessageId, showPopUpLoader,
            isUpdatePrepareMessage, prepareMessageButtonText, showAddMessagePopUp, prepareMessageTitle,
            selectedOrganizerInitialMessage, selectedOrganizerReminderMessage, selectedDownloadELMessage, showEmailMessagePopup, isUpdateMessage, buttonText,
            selectedMessageId, title, defaultMessageId, typeId, selectedReminderType
        } = this.state;
        const { preparerMessageStore: { preparerMessages },
            emailMessageStore: { emailMessages },
            companySettingStore: { companySettings: { defaultSettings: { initialEmail, engLetterReminderEmail, organizerReminderEmail, sourceDocReminderEmail, downloadEngagementLetterEmail, savedMessage }, authenticationSettings: { authenticationOption } } }
        } = this.props;
        const prepareId = selectedPrepareMessageId ? selectedPrepareMessageId : defaultPrepareMessageId;
        const selectedPreparerMessage = preparerMessages.find(message => message.id === prepareId);
        const emailId = selectedMessageId ? selectedMessageId : this.getSelectedMessageOnRender(typeId, initialEmail, engLetterReminderEmail, downloadEngagementLetterEmail);
        const selectedEmailMessage = emailMessages.find(message => message.id === emailId);
        return (
            <LoadingOverlay style={{ height: '100%' }}>
            <div>
                <h3 style={{ marginTop: '5px' }}>Client Instructions</h3>
                <hr />
                <EmailMessageComponent
                    heading={'Initial Email'}
                    description={'Organizer initial email sent to client'}
                    emailMessages={emailMessages.filter(message => message.type === EmailMessageType.InitialEmail)}
                    defaultMessage={initialEmail}
                    selectedMessage={selectedOrganizerInitialMessage}
                    onListItemClick={this.onOrganizerInitialListItemClick}
                    typeId={EmailMessageType.InitialEmail}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                />
                <hr></hr>
                <ReminderTemplateComponent
                    reminderDropDownOptions={this.getReminderDropDown()}
                    onChangeReminderDropdown={this.onChangeReminderDropdown}
                    selectedReminderType={selectedReminderType}
                    emailMessages={emailMessages.filter(message => message.type === selectedReminderType)}
                    defaultReminder={this.getDefaultReminder(selectedReminderType)}
                    selectedReminderMessage={selectedOrganizerReminderMessage}
                    onOrganizerReminderListItemClick={this.onOrganizerReminderListItemClick}
                    onEmailAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEmailEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onEmailDeleteButtonClick={this.deleteEmailMessage}
				/>
                <hr></hr>
                <EmailMessageComponent
                    heading={'Download Signed Document'}
                    description={'Download Signed Document'}
                    emailMessages={emailMessages.filter(message => message.type === EmailMessageType.DownloadEngagementLetterEmail)}
                    defaultMessage={downloadEngagementLetterEmail}
                    selectedMessage={selectedDownloadELMessage}
                    onListItemClick={this.onOrganizerDownloadEngagementLetterListItemClick}
                    typeId={EmailMessageType.DownloadEngagementLetterEmail}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                />
                <hr></hr>
                <EmailMessageModal
                    showState={showEmailMessagePopup}
                    onHide={this.onCancelButtonClick}
                    submitButtonClick={isUpdateMessage ? this.editEmailMessage : this.saveEmailMessage}
                    buttonText={buttonText}
                    title={title}
                    isUpdate={isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={showPopUpLoader}
                    emailMessages={emailMessages.filter(message => message.type === typeId)}
                    defaultMessage={defaultMessageId}
                    selectedEmailMessage={!isUpdateMessage ? initialEmailMessage : selectedEmailMessage ? selectedEmailMessage : initialEmailMessage}
                    variableList={getVariableList()}
                />
            </div>
            <Loader loading={showLoader} />
            </LoadingOverlay>
        );
    }
}