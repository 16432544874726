import * as React from 'react';
import { Button } from 'react-bootstrap';
import { EmailMessage, initialEmailMessage } from './../../../models/OrganizerComponentModels';
import { EmailMessageList } from './EmailMessageList';
import { EmailMessageView } from './EmailMessageView';

interface EmailMessageComponentProps {
    heading?: string;
    description?: string;
    emailMessages: EmailMessage[];
    defaultMessage: number;
    selectedMessage: number;
    onListItemClick: (messageId: number) => void;
    typeId: number;
    onAddButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onEditButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onDeleteButtonClick: (selectedMessageId: number, defaultMessage: number) => void;
}


export const EmailMessageComponent: React.StatelessComponent<EmailMessageComponentProps> = ({
    heading, defaultMessage, description, emailMessages, onAddButtonClick, onDeleteButtonClick, onEditButtonClick,
    onListItemClick, selectedMessage, typeId
}) => {
    let selectedEmailMessage = emailMessages.find(message => message.id === selectedMessage);
    selectedEmailMessage = selectedEmailMessage ? selectedEmailMessage : emailMessages.find(message => message.id === defaultMessage);
    return (
    <>
        {heading ?
            <div className="row">
                <div style={{ marginLeft: 16, marginBottom: 10, fontSize: '14px' }}>
                    <h3 className="text-primary">{heading}</h3>
                    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{description}</span>
                </div>
            </div>
          : null}
        <div className="row" style={{ position: "relative" }}>
            <div className="col-lg-5 col-sm-6" style={{ marginLeft: 17 }}>
                <div className="row" style={{ display: 'flow-root' }}>
                    <div className="pull-right">
                        <Button
                            data-test-auto="6A7380BB-5831-4E8B-AE6C-C27D511C45EA"
                            variant="info"
                            className="btn btn-info btn-lg glyphicon glyphicon-plus"
                            style={{ padding: 12, minWidth: 40 }}
                            onClick={() => onAddButtonClick(defaultMessage, selectedMessage, typeId)}
                            title="Add Message">
                        </Button>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 207, border: 'rgb(221, 221, 221) 1px solid', fontSize: '14px' }}>
                    <div style={{ width: '100%' }}>
                        <EmailMessageList
                            emailMessages={emailMessages}
                            defaultMessage={defaultMessage}
                            selectedMessage={selectedMessage}
                            onListItemClick={onListItemClick}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-sm-6 client-instructions-email-message-icon-row-wrapper">
                <div className="row" style={{ display: 'flow-root' }}>
                    <div className="pull-right">
                        <Button
                            data-test-auto="C55C7FAC-3A7E-4926-8A98-7A9586AC8272"
                            variant="default"
                            className="btn btn-secondary btn-lg fas fa-edit"
                            style={{ padding: 12, minWidth: 40 }}
                            title="Edit Message"
                            onClick={() => onEditButtonClick(defaultMessage, selectedMessage, typeId)}
                        >
                        </Button>
                        <Button
                            data-test-auto="F9D09E38-1B12-4F2C-B0B5-F605F62E5206"
                            variant="default"
                            className="btn btn-secondary btn-lg fas fa-trash-alt"
                            title="Delete Message"
                            style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                            onClick={() => onDeleteButtonClick(selectedMessage, defaultMessage)}
                        >
                        </Button>
                    </div>
                </div>
                <div className="row text-message">
                    <EmailMessageView
                        selectedMessage={selectedEmailMessage ? selectedEmailMessage : initialEmailMessage}
                    />
                </div>
            </div>
        </div>
    </>
)
}